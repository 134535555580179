import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";

const newCampaignTour = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutNewCampService {

  constructor(private router: Router,) { }

  newCampaignTour() {

		newCampaignTour.on('complete', () => {
      this.router.navigate(['/dashboard/campaign']);
		})
		newCampaignTour.on('cancel', () => {
			this.router.navigate(['/dashboard/campaign']);
		})

    newCampaignTour.addSteps([{
      id: 'start',
      title: 'Creating A New Campaign',
      text: 'This is an interactive tutorial and will cover every aspect of creating your own campaign.<br><br>\
      As always I will be assuming the role of your immaculate tour guide. At the end you will be proficient at creating new campaigns.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {return this.next();},text: 'Let\'s Start'}
      ],
    },{
      id: 'step1',
      title: 'New Shortcode Campaign',
      text: 'Create your very own campaign based on keywords sent from entries to your Shortcode.<br><br>\
      <i>- "Keyword": a word or concept of great significance.</i><br><br>',
      attachTo: {element: '.mainS17', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step2',
      title: 'Steps',
      text: 'This tutorial will guide you through the 7x steps you need to complete to successfully create your campaign.<br><br>\
      Step #1 will require you to provide some basic details on your campaign.<br><br>\
      <i>- Name, Description, Start- & End Dates</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step3',
      title: 'Campaign Name',
      text: 'The first thing you should do is think of a catchy name that we can call your campaign.<br><br>\
      Your campaign\'s name will be used to uniquely identify it.<br><br>\
      <i>- Once you start having multiple campaigns you will be thankful for choosing descriptive names.</i><br><br>\
      <mark>- Please provide your campaign with a name before we proceed.</mark>',
      attachTo: {element: '.s1t3', on: 'bottom'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step4',
      title: 'Campaign Description',
      text: 'Now you need to describe your campaign.<br><br>\
      What is the campaign for, the goal, function or just something that you could easily recognize your campaign with.<br><br>\
      <i>- A description is considered as "good" if someone else were to read your description and understand the goal of your campaign.</i><br><br>\
      <mark>- Try providing a description for your campaign before we proceed.</mark>',
      attachTo: {element: '.s1t4', on: 'top'},
      buttons: [
				{action() {return this.back();},text: 'Back'},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step5',
      title: 'Campaign Start Date & Time',
      text: 'This indicates the date & time on which your campaign will go live.<br><br>\
			Once a campaign is live it will be able to start receiving entries.<br><br>\
			<i>You will notice that these have default values, for this tutorial lets keep these values.</i>',
      attachTo: {element: '.s1t5', on: 'top'},
      canClickTarget: false,
      buttons: [
				{action() {return this.back();},text: 'Back'},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step6',
      title: 'Campaign End Date & Time',
      text: 'This indicates the date & time on which your campaign will close.<br><br>\
      Once a campaign has been closed it will stop receiving any entries and respond with it\'s campaign closed message.<br><br>\
			<i>Similar to the starting date & time these also have default values, for this tutorial lets keep these values.</i>',
      attachTo: {element: '.s1t6', on: 'top'},
      canClickTarget: false,
      buttons: [
				{action() {return this.back();},text: 'Back'},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step7',
      title: 'Selecting Start & End',
      text: 'Normally you would click on the respective fields to select start & ending dates.<br><br>\
      But for now we are keeping the default dates & times - Currently your campaign is set to start earliest today and end at midnight tonight.<br><br>',
      attachTo: {element: '.s1t7', on: 'top'},
      canClickTarget: false,
      buttons: [
				{action() {return this.back();},text: 'Back'},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step8',
      title: 'Next',
      text: 'Now that you have completed step #1 you can click on "Next" to proceed.<br><br>\
      <mark>- Click on "Next" to proceed to the next step.</mark>',
      attachTo: {element: '.s1t8', on: 'top'},
      advanceOn: {selector: '.s1t8', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'stepCheck',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or you will be taken to the next step.<br><br>\
      If you see a red error popup it means that there are some invalid inputs.<br><br>\
      If not then it means everything is valid and you are ready to proceed to step #2!',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('step2Flag') === null) {
          return this.show('stepError')} else {
          return this.show('step9');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepError',
      title: 'Oops...',
      text: 'It seems like either your campaign name or description is invalid.<br><br>\
      Please click on the "Fix" button and we will circle back to the input fields to try again.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    },{
      id: 'stepError2',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step3');},text: 'Start again',}
      ],
    },{
      id: 'step9',
      title: 'Step #2',
      text: 'This step will require you to provide a shortcode, an open reply message and a closed reply message.<br><br>\
      Let\'s have a look.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step10',
      title: 'Campaign Shortcode',
      text: 'A shortcode is the number to which all entries will be sent to for entering your campaign.<br><br>\
      Each shortcode has it\'s own value - This is what the entry is charged at.<br><br>\
      <mark>- Please select a shortcode through the small window provided to proceed to the next step.</mark>',
      attachTo: {element: '.s2t10', on: 'top'},
      modalOverlayOpeningPadding: 20,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step11',
      title: 'Running Campaign Reply Message',
      text: 'This is the reply message that gets sent to a successful entry if the campaign is currently live.<br><br>\
			The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
			<i>**Please note that if you select a shortcode with a value lower that R1.50 you will not be able to provide a Running Reply Message.</i><br><br>\
      <mark>- Try to enter a suitable running campaign reply message (If applicable) before we proceed.</mark>',
      attachTo: {element: '.s2t11', on: 'bottom'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step12',
      title: 'Closed Campaign Reply Message',
      text: 'This is the reply message that gets sent to entries if the campaign is has already passed it\'s end date and is closed.<br><br>\
      The cost of this message is included in the shortcode value which the entree is charged.<br><br>\
      <i>Lets leave this on the default value shall we?</i>',
			attachTo: {element: '.s2t12', on: 'top'},
			canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step13',
      title: 'Back',
      text: 'Should you wish to go to the previous page you may click on the "Back" button.<br><br>\
      <i>- For this tutorial we will not be going back we want to go forward.</i><br><br>',
      attachTo: {element: '.s2t13', on: 'top'},
      modalOverlayOpeningPadding: 10,
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step14',
      title: 'Next',
      text: 'After completing all required fields on step #2 page you may click on "Next" to proceed to the next step.<br><br>\
      <mark>- Click on "Next" when you are ready to go to step #3</mark>',
      attachTo: {element: '.s2t14', on: 'top'},
      advanceOn: {selector: '.s2t14', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'stepChecka',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or you will be taken to the next step.<br><br>\
      If you see a red error popup it means that there are some invalid inputs.<br><br>\
      If not then it means everything is valid and you are ready to proceed to step #3!',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('step3Flag') === null) {
          return this.show('stepErrora')} else {
          return this.show('step15');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepErrora',
      title: 'Oops...',
      text: 'It seems like either you forgot to select a shortcode or your running reply message is invalid.<br><br>\
      Please click on the "Fix" button and we will circle back to the input fields to try again.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    },{
      id: 'stepError2a',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step10');},text: 'Try again',}
      ],
    },{
      id: 'step15',
      title: 'Step #3 - Keywords',
      text: 'On this step you will be required to provide at least 1x keyword and description.<br><br>\
      This keyword will be used to associate an entry with your campaign.<br><br>\
      <i>- No duplicate keywords are allowed on the same shortcode - regardless of the campaign.</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step16',
      title: 'Keyword',
      text: 'Before a keyword can be added it will be checked for possible conflicts.<br><br>\
      If a conflict is found you will have to use a different keyword.<br><br>\
      <i>- You require at least 1x keyword and may have up to 3x keywords.</i><br><br>\
      <mark>- Try to type in a suitable keyword before we proceed.</mark>',
      attachTo: {element: '.s3t16', on: 'right'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step17',
      title: 'Keyword Description',
      text: 'After choosing a suitable keyword you need to provide a description for said keyword before it can be added.<br><br>\
      This description serves a similar purpose to that of the campaign description on step #1.<br><br>\
      <mark>- Try to type in a suitable description before we proceed.</mark>',
      attachTo: {element: '.s3t17', on: 'left'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step18',
      title: 'Add Keyword',
      text: 'Once you have provided both a keyword and a description you may click on "Add Keyword".<br><br>\
      This will check your keyword is already being used on the shortcode that you selected on step #2.<br><br>\
      <mark>- Click on "Add Short code" to proceed.</mark>',
      attachTo: {element: '.s3t18', on: 'bottom'},
      advanceOn: {selector: '.s3t18', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'stepCheckb',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or your Keyword will be added.<br><br>\
      If you see a red error popup it means that there are some invalid inputs or your keyword is not available for the selected shortcode.<br><br>\
      If not then it means everything is valid and your keyword is ready to be registered!',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('keywordFlag') === null) {
          return this.show('stepErrorb')} else {
          return this.show('step19');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepErrorb',
      title: 'Oops...',
      text: 'It seems like either your keyword is already in use for this shortcode or is invalid.<br><br>\
      Please click on the "Fix" button and we will circle back to the input fields to try again.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    },{
      id: 'stepError2b',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step16');},text: 'Try again',}
      ],
    },{
      id: 'step19',
      title: 'Keyword Table',
      text: 'Well done! It seems as if your keyword is available and has been added to your keyword list.<br><br>\
      You have the option of adding 2x additional keywords. You can also remove a keyword by clicking on "remove".<br><br>\
      <i>- For this tutorial we will only be adding this one keyword to your campaign.</i><br><br>',
      attachTo: {element: '.s3t19', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step20',
      title: 'Next',
      text: 'When you are happy with your chosen keyword(s) you may click on "Next" to proceed.<br><br>\
      <mark>- Click on "Next" to proceed to step #4</mark>',
      attachTo: {element: '.s3t20', on: 'top'},
      advanceOn: {selector: '.s3t20', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step21',
      title: 'Step #4 - Reports',
      text: 'If you would like to receive email reports on your campaign stats you can set it up on this page.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step22',
      title: 'Setting Up Reports',
      text: 'Here you can see there are 3x different options to choose.<br><br>\
      "Get No Reports" will opt you out of receiving any sort of reports via email.<br><br>\
      "Get Daily Reports" will automatically schedule reports to be sent to the email provided every day @ 7am.<br><br>\
      "Customize Reports" will allow you to select specific days & times on which to receive reports.<br><br>\
      <mark>- Please select "Get Daily Reports" to proceed</mark>',
      attachTo: {element: '.s4t22a', on: 'right'},
      advanceOn: {selector: '.s4t22b', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step23',
      title: 'Email Address',
      text: 'After selecting daily reports you need to provide an email address to receive reports on.<br><br>\
      You have the option of adding multiple email addresses.<br><br>\
      <i>- If you provide more than 1x email address you need to separate each of them with a semicolon (;).</i><br><br>\
      <mark>- Try providing an email address before we can proceed</mark>',
      attachTo: {element: '.s4t23', on: 'top'},
      beforeShowPromise: function() { 
        return new Promise(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step24',
      title: 'Next',
      text: 'When you are ready you may click on "Next" to proceed.<br><br>\
      <mark>- Click on "Next" to proceed to step #5</mark>',
      attachTo: {element: '.s4t24', on: 'top'},
      advanceOn: {selector: '.s4t24', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'stepCheckc',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or you will be taken to step #5.<br><br>\
      If you see a red error popup it means that the email address that you have provided is invalid.<br><br>\
      If not then it means everything is valid and you are ready for step #5!',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('step4Flag') === null) {
          return this.show('stepErrorc')} else {
          return this.show('step25');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepErrorc',
      title: 'Oops...',
      text: 'It seems like you either forgot to provide an email or it is invalid.<br><br>\
      Please click on the "Fix" button and we will circle back to the input fields to try again.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    },{
      id: 'stepError2c',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step23');},text: 'Try again',}
      ],
    },{
      id: 'step25',
      title: 'Step #5 - SMS2Email',
      text: 'On this page you have the option of receiving email notifications every time your campaign receives an entry.<br><br>\
      This is ideal for campaigns where human response is required. i.e. A danger alert campaign.<br><br>\
      <i>- This feature is not recommended if you expect to have large amounts of daily entries.</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step26',
      title: 'Enable SMS2Email',
      text: 'To enable this service you simply select "Send me Emails for each entry received".<br><br>\
      Then you will be required to provide at least one email address.<br><br>\
      <mark>- To proceed please select "Send me Emails for each entry received"</mark>',
      attachTo: {element: '.s5t26b', on: 'bottom'},
      advanceOn: {selector: '.s5t26b', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step27',
      title: 'Email Address',
      text: 'After opting in for SMS2Email you need to provide an email address.<br><br>\
      You may also add multiple email addresses.<br><br>\
      <i>- If you add multiple addresses you need to separate them with semicolons (;).</i><br><br>\
      <mark>- Provide at least one email address to proceed.</mark>',
      attachTo: {element: '.s5t27', on: 'top'},
      modalOverlayOpeningPadding: 10,
      beforeShowPromise: function() { 
        return new Promise(function(resolve) {
          setTimeout(function() {
            resolve();
          }, 200);
        });
      },
      buttons: [
        {action() { return this.next();},text: 'Next',}
      ],
    },{
      id: 'step28',
      title: 'CC Email Address',
      text: 'You have the option to provide email addresses to be CCed in these entry alert emails.<br><br>\
      This field is optional.<br><br>\
      <i>- Like with the previous field - If you provide multiple email addresses to CC they need to be separated by semicolons (;).</i><br><br>\
      <mark>- You now have the option to provide a CC email or to simply move on and leave this field open.</mark>',
      attachTo: {element: '.s5t28', on: 'top'},
      modalOverlayOpeningPadding: 10,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step29',
      title: 'Next',
      text: 'When you are ready you may click on "Next" to proceed.<br><br>\
      <mark>- Click on "Next" to proceed to step #6</mark>',
      attachTo: {element: '.s5t29', on: 'top'},
      advanceOn: {selector: '.s5t29', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'stepCheckd',
      title: 'Verifying...',
      text: 'At this stage you will either see an error pop up or you will be taken to step #6.<br><br>\
      If you see a red error popup it means that the email address that you have provided is invalid.<br><br>\
      If not then it means everything is valid and you are ready for step #6!',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('step5Flag') === null) {
          return this.show('stepErrord')} else {
          return this.show('stepBlacklist');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepErrord',
      title: 'Oops...',
      text: 'It seems like you either forgot to provide an email or it is invalid.<br><br>\
      Please click on the "Fix" button and we will circle back to the input fields to try again.<br><br>',
      attachTo: {element: '.errorBtn', on: 'bottom'},
      canClickTarget: true,
      advanceOn: {selector: '.errorBtn', event: 'click'},
    },{
      id: 'stepError2d',
      title: 'Back to the drawing board',
      text: 'If you are sure that everything is correct - try reloading the page if this happens again.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('step27');},text: 'Try again',}
      ],
    },{
      id: 'stepBlacklist',
      title: 'Blacklist Numbers',
			text: 'On this step you can manually type in numbers to blacklist.<br><br>\
			Blacklisted numbers are prohibited from entering your campaigns.<br><br>\
			We will not be blacklisting any numbers for this tutorial - but rather address it in the Manage Campaigns Guide.<br><br>\
			<i>- **Please note that you can upload blacklist files to existing campaigns from the manage campaigns page.</i><br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'stepBLNExt',
      title: 'Next',
      text: 'When you are ready you may click on "Next" to proceed.<br><br>\
      <mark>- Click on "Next" to proceed to step #7</mark>',
      attachTo: {element: '.blNext', on: 'top'},
      advanceOn: {selector: '.blNext', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step30',
      title: 'Step #7 - Terms & Conditions',
      text: 'Congratulations! You have made it to the final step.<br><br>\
      The last hurdle between you and your campaign is to Read and Accept our Terms & Conditions.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step31',
      title: 'Terms & Conditions',
      text: 'To read our Terms & Conditions you can click on the "Terms & Conditions" blue text.<br><br>\
      After reading through every page you can click on the checkbox to the left of "I Accept the...".<br><br>\
      <i>- I will know if you have actually read the terms & conditions.</i><br><br>\
      <mark>- When you are ready you can accept the T&Cs and we will proceed to a quick 70 mark T&C pop-quiz.</mark>',
      attachTo: {element: '.s6t31a', on: 'bottom'},
      advanceOn: {selector: '.s6t31b', event: 'click'},
      modalOverlayOpeningPadding: 10,
    },{
      id: 'step32',
      title: 'Save Campaign',
      text: 'Once you click on this button your campaign will be created.<br><br>\
      ***It should be noted that this tutorial campaign will NOT be created and all data provided will be reset on completion of this tutorial***.<br><br>\
      <i>- You are now ready to craft your very own campaigns.</i><br><br>',
      attachTo: {element: '.s6t32', on: 'top'},
			canClickTarget: false,
			modalOverlayOpeningPadding: 10,
			buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'end',
      title: 'Tutorial Completed',
      text: 'You have successfully & skillfully mastered the art of creating your own keyword campaign.<br><br>\
      This concludes our tutorial on Creating Keyword Campaigns.<br><br>\
      <i>- Thank you for taking the time to know me a little better.</i><br><br>',
      buttons: [
        {action() {return this.complete();},text: 'End Tutorial',}
      ],
    }
  ]);

  
  newCampaignTour.start();

    
  }
}
