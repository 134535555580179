import { Component, OnInit } from '@angular/core';
import { RegisterLoginComponent } from '../../modals/register-login/register-login.component';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
@Component({
  selector: 'app-faq-landing',
  templateUrl: './faq-landing.component.html',
  styleUrls: ['./faq-landing.component.scss']
})
export class FaqLandingComponent implements OnInit {
  modalRef: MDBModalRef;
  
  constructor(private modalService: MDBModalService,) { }

  ngOnInit() {
  }

  openModal() {
    this.modalRef = this.modalService.show(RegisterLoginComponent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: '',
        containerClass: 'overflow-auto',
        animated: false
    });
  }

}
