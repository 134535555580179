import { Component, OnInit, AfterViewInit, OnDestroy, Input } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { FileUploader } from 'ng2-file-upload';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { Router } from "@angular/router";
import { ConfirmComponent } from "../../../modals/other/confirm/confirm.component";
import { SuccessModalComponent } from "../../../modals/other/success-modal/success-modal.component";
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { NavbarService } from '../../../services/navbar.service';
import { CreditsService } from "../../../services/credits.service";
import { TestService } from "../../../services/file/test.service";
import { environment } from "../../../../environments/environment";

const URL = environment.fileUpURL + 'sms';

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  scheduledForm: FormGroup;

  public submitted: boolean;
  public loading: boolean;
  public submittedToQueue: boolean;
  public scheduled: boolean;

  private messageQueue: Array<any>;

  private tempArr: any;
  private resp: any;

  public creditBal: number;
  public amountToRemove: number;

  public messagesInQueue: number;
  public badRows: number;

  public hours: any;
  public minutes: any;

  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  public day = this.pipe.transform(this.now, 'yyyy-MM-dd');

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Done',

    firstDayOfWeek: 'su',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };


  @Input() progressLoaded: number = 0;
  public uploader: FileUploader = new FileUploader({ url: URL, queueLimit: 1, itemAlias: 'file' });

  // Image Variables
  public initial: boolean;
  public error: string;
  public showButton: boolean;
  public fileNameShow: string;
  public uploadStatus: boolean;
  public uploadError: boolean;
  public endStatus: boolean;
  public filename: string;
  public filenameToRequestRead: string;
  public filenameToConfirmQueue: any;
  public fileType: string;
  public payload: any;
  public confirmed: boolean;
  public link: any;
  private user_id: number;


  headMessageQueue = ['Cell Number', 'Message', 'Date to Send', 'Batch Name'];

  constructor(
    private testservice: TestService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private router: Router,
    private navbarService: NavbarService,
    private creditService: CreditsService
  ) {
  }



  ngOnInit() {
    this.link = {};
    this.creditBal = 0;
    this.amountToRemove = 0;
    this.uploader = new FileUploader({ url: URL, queueLimit: 1, itemAlias: 'file' });
    this.submitted = false;
    this.loading = false;
    this.submittedToQueue = false;
    this.scheduled = false;
    this.initial = false;
    this.showButton = false;
    this.uploadStatus = false;
    this.uploadError = false;
    this.endStatus = false;
    this.confirmed = false;
    this.user_id = 0;

    this.messageQueue = [];

    this.tempArr = {};
    this.filenameToConfirmQueue = {};
    this.payload = {};

    this.messagesInQueue = 0;
    this.badRows = 0;
    this.hours = 0;
    this.minutes = 0;
    this.progressLoaded = 0;

    this.error = '';
    this.fileNameShow = '';
    this.filename = '';
    this.filenameToRequestRead = '';
    this.fileType = '';

    this.myDatePickerOptions.disableUntil.year = (new Date()).getFullYear();
    this.myDatePickerOptions.disableUntil.month = (new Date()).getMonth() + 1;
    this.myDatePickerOptions.disableUntil.day = (new Date()).getDate() - 1;

    this.scheduledForm = this.fb.group({
      sms_batchName: ['', Validators.required],
      sms_dateToSend: ['', Validators.required],
      sms_timeToSend: ['', Validators.required]
    });

    this.uploader.onAfterAddingFile = (file) => {
      if (this.uploader.queue.length > 1) {
        this.uploader.removeFromQueue(this.uploader.queue[0]);
      }
      file.withCredentials = false;
      this.fileNameShow = file.file.name;

      this.showButton = true;
    };


    this.uploader.onProgressItem = (progress: any) => {
      this.progressLoaded = progress['progress'];
    };

    this.uploader.onErrorItem = (response) => {
      this.error = 'There seems to be a network error. Please check your internet connection and try to upload your file again';
    };


    this.uploader.onCompleteItem = (item: any, response: any, status: any, headers: any) => {
      this.filenameToRequestRead = response;
      this.filename = item.file.name;
      this.uploadStatus = item.isUploaded;
      this.uploadError = item.isError;
      this.loading = false;
      if (!this.uploadError) {
        this.showButton = false;
      }
    };
    this.getCreditBalance();
  }



  get s() { return this.scheduledForm.controls; }
  get sms_dateToSend() { return this.scheduledForm.get('sms_dateToSend'); }
  get sms_timeToSend() { return this.scheduledForm.get('sms_timeToSend'); }
  get sms_batchName() { return this.scheduledForm.get('sms_batchName'); }

  getCreditBalance() {
    this.navbarService.getNavCredits()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          this.creditBal = this.resp.uc_amt;
          this.user_id = this.resp.user_id;
        }
      );
  }

  deductCreditBalance() {
    this.creditService.updateCreditBalance({ deduct: this.messagesInQueue })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.openModalSuccess();
          this.ngOnInit();
        }
      )
  }


  uploadSelectedFile() {
    this.loading = true;
    this.uploader.uploadAll()
  }

  onAddToQueue() {
    if (this.filenameToRequestRead === 'File type error') {
      this.openModalErrorFile();
      return;
    }
    this.submitted = true;

    if (this.sms_batchName.invalid && this.scheduled === true) {
      this.openModalErrorBatchName();
      return;
    }

    // stop if invalid
    if (this.scheduled && this.scheduledForm.invalid) {
      this.openModalErrorSchedule();
      return;
    }



    if (this.filenameToRequestRead === '') {
      this.openModalErrorNoFile();
      return;
    }

    if (this.scheduled) {
      this.scheduledDate = this.sms_dateToSend.value + ' ' + this.sms_timeToSend.value + ':00';
    } else {
      this.scheduledDate = this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
    }

    if (this.scheduled === true && this.day === this.sms_dateToSend.value) {
      this.hours = (new Date()).getHours();
      this.minutes = (new Date()).getMinutes();
      if (this.hours < 10) { this.hours = '0' + this.hours }
      if (this.minutes < 10) { this.minutes = '0' + this.minutes }
      if ((this.sms_timeToSend.value <= this.hours + ':' + this.minutes)) {
        this.openModalErrorInvalidTime();
        return;
      }
    }

    this.payload = {
      filenameToRequestRead: this.filenameToRequestRead,
      sms_dteCreated: this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss'),
      sms_dteTime2Send: this.scheduledDate,
      scheduled: this.scheduled,
      sms_batchName: this.sms_batchName.value,
      user_id: this.user_id
    }

    this.loading = true;
    this.testservice.testUpload(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.tempArr = data;
        if (this.tempArr.error === 'Tab delimit error') {
          this.openModalErrorTab();
          this.submitted = false;
          return;
        }

        if (this.tempArr.error === 'All messages over 160 error') {
          this.openModalErrorBadRowsAllMessagesTooLong();
          this.ngOnInit();
          return;
        }
        this.badRows = this.tempArr.badrows;
        if (this.badRows > 0) {
          this.openModalErrorBadRowsTooLongMessage();
        }
        this.messagesInQueue = this.tempArr.total;
        this.filenameToConfirmQueue = { filenameToConfirmQueue: this.tempArr.newFilename };
        this.tempArr = this.tempArr.firstTen;
        for (let index = 0; index < this.tempArr.length; index++) {
          const element = this.tempArr[index];

          this.messageQueue.push({
            sms_cell: element.sms_cell,
            sms_msg: element.sms_msg,
            sms_scheduled: this.scheduled,
            sms_dteTime2Send: this.payload.sms_dteTime2Send,
            sms_batchName: this.sms_batchName.value
          });
          this.submittedToQueue = true;
          this.submitted = false;
          this.loading = false;

        }


      });
  }

  onSubmitQueue() {
    if (this.creditBal < this.messagesInQueue) {
      this.openModalCredits();
      return;
    } else {
      this.loading = true;
      this.testservice.commitFile(this.filenameToConfirmQueue)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          this.deductCreditBalance();
        }, error => { this.loading = false });
    }
  }

  onDeleteQueue() {
    this.openModalSuccessDelete();
    this.ngOnInit();
  }


  onDownloadBadFile() {


    this.testservice.getBadRows(this.filenameToConfirmQueue)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.link = data;
          var newBlob = new Blob([this.link], { type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" });
          const data2 = window.URL.createObjectURL(newBlob);
          var dllink = document.createElement('a');
          dllink.href = data2;
          dllink.download = 'Bad_Rows_Bulk_Send.xlsx';
          dllink.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));

          setTimeout(function () {
            // For Firefox it is necessary to delay revoking the ObjectURL
            window.URL.revokeObjectURL(data2);
            dllink.remove();
          }, 100)
        }
      );
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', amount: 0, description: '', btnText: '' }
    }
  }

  openModalConfirmSend() {
    this.modalOptions.data = {
      heading: 'Are you ready to send?',
      content: {
        heading: 'Are you sure you want to submit a total of ',
        amount: this.messagesInQueue,
        description: ' message(s)?',
        btnText: 'Send them all'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result) {
          this.onSubmitQueue();
        }
      });
  }

  openModalConfirmDelete() {
    this.modalOptions.data = {
      heading: 'Really remove all messages?',
      content: {
        heading: 'Are you sure you want to remove a total of ',
        amount: this.messagesInQueue,
        description: ' message(s) from your queue?',
        btnText: 'Remove them all'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result) {
          this.onDeleteQueue();
        }
      });
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', btnText: '' }
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Done and done!',
      content: {
        heading: 'Your message queue has been successfully submitted to be sent - Look at you taking care of business!',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Taken care of!',
      content: {
        heading: 'Your message queue has been successfully removed.',
        btnText: 'Good riddance'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: '' }
    }
  }

  openModalErrorFile() {
    this.modalOptionsErrorInput.data = {
      heading: 'This file looks wrong...',
      content: {
        heading: 'You have uploaded the wrong file type. Please upload a .xls or .xlsx file. Please try again.',
        suggest: 'Suggested Actions:',
        fix1: 'Please ensure that you upload only one of the suggested file formats (.xls or .xlsx).',
        fix2: 'Please ensure that the field values are in the correct order - See the example files.',
        fix3: 'Keep the character count of each message to 160 characters or less - Messages above 160 characters will be excluded.',
        btnText: 'Try again'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
    this.ngOnInit();
  }

  openModalErrorBatchName() {
    this.modalOptionsErrorInput.data = {
      heading: 'Your batch name looks wrong...',
      content: {
        heading: 'There seems to be an issue with the batch name field.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided a batch name.',
        fix2: 'A batch name is only required if messages are scheduled.',
        fix3: '',
        btnText: 'Fix Batch Name'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorSchedule() {
    this.modalOptionsErrorInput.data = {
      heading: 'No scheduled date selected...',
      content: {
        heading: 'There seems to be an issue with the scheduled date and time fields',
        suggest: 'Suggested Actions:',
        fix1: 'Select a date from the date selector field.',
        fix2: 'Select a time from the time selector field.',
        fix3: 'The time selected should not be in the past.',
        btnText: 'Fix schedule'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalCredits() {
    this.amountToRemove = this.messagesInQueue - this.creditBal;
    this.modalOptionsErrorInput.data = {
      heading: 'Not enough credits...',
      content: {
        heading: 'It seems that your current credit balance of ' + this.creditBal + ' is not sufficient to facilitate the ' + this.messagesInQueue + ' messages in your queue.',
        suggest: 'Suggested Actions:',
        fix1: 'Try removing at least ' + this.amountToRemove + ' messages from your queue.',
        fix2: 'Top up your credit balance by purchasing a credit bundle.',
        fix3: 'Smile at strangers, laugh at yourself, and know that you’re free to start over.',
        btnText: 'Got it'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorNoFile() {
    this.modalOptionsErrorInput.data = {
      heading: 'You forgot to upload a file...',
      content: {
        heading: 'It seems like you forgot to select and upload your file.',
        suggest: 'Suggested Actions:',
        fix1: 'Select a file in one of the suggested formats (.xls or .xlsx).',
        fix2: 'Then click on upload file.',
        fix3: '',
        btnText: 'try again'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorTab() {
    this.modalOptionsErrorInput.data = {
      heading: 'This file looks wrong...',
      content: {
        heading: 'There was a problem reading your file as it seems to be in an invalid format. Please try again.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you upload either a .XLSX or a .XLS file (Excel).',
        fix2: 'Ensure that there are no headings.',
        fix3: 'Then try again',
        btnText: 'try again'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
    this.ngOnInit();
  }

  openModalErrorBadRowsTooLongMessage() {
    this.modalOptionsErrorInput.data = {
      heading: 'Some bad news...',
      content: {
        heading: 'I just finished analyzing your file, it seems that ' + this.badRows + ' entries are bad. I separated them from your list and compiled them into a neat little file that you can download.',
        suggest: 'Suggested Actions:',
        fix1: 'Please ensure that messages in your file does not exceed 306 characters.',
        fix2: 'Ensure all numbers provided are valid.',
        fix3: 'To make life easier you may download you bad row file as reference.',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }


  openModalErrorBadRowsAllMessagesTooLong() {
    this.modalOptionsErrorInput.data = {
      heading: 'Some bad news...',
      content: {
        heading: 'We just finished analyzing your file, it seems that all of the messages or numbers in your file were either invalid, too long or contains special characters.',
        suggest: 'Suggested Actions:',
        fix1: 'Please ensure that all messages in your file does not exceed 306 characters.',
        fix2: 'Messages exceeding 306 characters will be excluded from your final queue.',
        fix3: 'This feature is coming soon.',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Well this is embarrassing...',
      content: {
        heading: 'There was an error processing your request and was unsuccessful.',
        suggest: 'Suggested Actions:',
        fix1: 'Network: Check your internet connection and try again.',
        fix2: 'Internal: It might be our fault, and we are working on it.',
        fix3: 'Feel free to contact us if this error persists.',
        btnText: 'It happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorInvalidTime() {
    this.modalOptionsErrorInput.data = {
      heading: 'Great Scott!!!',
      content: {
        heading: 'We are firm believers in time travel. To prevent the space-time continuum from collapsing we can not allow you to schedule messages to be sent to the past.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the selected schedule time is after the current time.',
        fix2: '"Your future is whatever you make it. So make it a good one."',
        fix3: 'Keep calm and try again.',
        btnText: 'Thanks Doc'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {

  }
}




