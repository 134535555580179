import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, from } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { Router, ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ErrorComponent } from '../../modals/other/error/error.component';

import { LoginService } from '../../login.service';
import { RegisterLoginComponent } from '../../modals/register-login/register-login.component';
import { ForgotPasswordComponent } from '../../modals/forgot-password/forgot-password.component';
import { ActivateAccountComponent } from "../../modals/activate-account/activate-account.component";
import TypeIt from 'typeit';




@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss']
})
export class LandingComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();

  modalRef: MDBModalRef;
  login: FormGroup;
  public submitted: boolean;
  public loading: boolean;
  public error: boolean;
  private tempResp: any;
  private tempUserId: number;
  private reqCount: number;


    returnUrl: string;

    constructor(
      private modalService: MDBModalService,
      private dataservice: LoginService,
      private fb: FormBuilder,

      private actRoute: ActivatedRoute,
      private router: Router,
      private route: ActivatedRoute,
  ) {
    
      // redirect to home if already logged in
      // if (this.authenticationService.currentUserValue) { 
      //     this.router.navigate(['/']);
      // }
  }

  ngOnInit() {
    this.reqCount = 0;
    this.error = false;
    this.tempUserId = 0;
    this.tempResp = {};
    this.loading = false;
    this.submitted = false;
    this.login = this.fb.group({
      user_name: ['', Validators.required],
      user_pw: ['', Validators.required]
    });

        // reset login status
    this.dataservice.logout();

      // get return url from route parameters or default to '/'
      this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

      new TypeIt('#replaceStrings', {
        speed: 30,
        breakLines: false,
        waitUntilVisible: true,
        loop: true,
        nextStringDelay: 400,
        startDelay: 1000,
        lifelike: true
      })
      .type("is designed to put thousands of")
      .pause(300)
      .options({speed: 200})
      .delete(12)
      .options({speed: 30})
      .pause(100)
      .type("<strong>millions</strong> of your clients within reach")
      .pause(300)
      .type(", in just a few clicks!")
      .pause(2500)
      .delete()
      .type("allows you to easily setup and manage your mobile campaigns")
      .pause(300)
      .type(" - BulkSMS,")
      .pause(300)
      .type(" SMS/USSD Campaigns")
      .pause(300)
      .type(" <strong>and much more!</strong>")
      .pause(3000)
      .delete()
      .type("is a prepaid service for BulkSMS.")
      .pause(2000)
      .delete()
      .options({speed: 200})
      .type("<strong> - Direct, Effective and Personal!</strong>")
      .options({speed:30})
      .pause(2000)
      .go()
  }

  onSubmitLogin() {
    this.submitted = true;

    // stop if invalid
    if (this.login.invalid) {
      return;
    }
    this.loading = true;
    this.userLogin();
  }

  get p() {return this.login.controls; }

  userLogin() {
    this.reqCount = 0;
    this.dataservice.login(this.login.value)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.reqCount++
        this.tempResp = data;
        if (this.tempResp.token === null && this.reqCount === 1) {
          this.tempUserId = this.tempResp.user;
          this.loading = false;
          this.openModalActivateAccount();
          this.modalRef.content.action
          .pipe(takeUntil(this.ngUnsubscribe))
          .subscribe( (result: any) => { 
            if (result) {
              this.onSubmitLogin();
            } 
          });
          return
        } else if (this.tempResp.token !== null) {
          window.location.replace('/dashboard/campaign');
        }
        
        
        //this.router.navigate(['dashboard/campaign']);
      },
      error => {
        if (error === "Unknown Error") {
          this.openModalErrorServerDown();
        } else {
          this.openModalErrorTimeout();
          this.error = true;
        }
        this.loading = false;
      }
    );
  }

  openModal() {
    this.modalRef = this.modalService.show(RegisterLoginComponent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: '',
        containerClass: 'overflow-auto',
        animated: false
    });
  }

  openModalActivateAccount() {
    this.modalRef = this.modalService.show(ActivateAccountComponent, {
        backdrop: true,
        keyboard: true,
        focus: true,
        show: false,
        ignoreBackdropClick: true,
        class: '',
        containerClass: 'overflow-auto',
        animated: false,
        data: {content: {user_id: this.tempUserId}}
    });
  }

  openForgotPasswordModal() {
    this.modalRef = this.modalService.show(ForgotPasswordComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: 'overflow-auto',
      animated: false
  });
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorTimeout() {
    this.modalOptionsError.data = {
      heading: 'Login Failed Successfully...',
      content: {
        heading: 'Unfortunately the details that you have provided are not valid...',
        suggest: 'Suggested Actions:',
        fix1: 'Try providing valid login details next time.',
        fix2: 'If you forgot you may click on "Forgot Password" that just appeared.',
        fix3: 'If all else fails feel free to contact us.',
        btnText: 'darn...'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

  openModalErrorServerDown() {
    this.modalOptionsError.data = {
      heading: 'Temporarily Down for Maintenance...',
      content: {
        heading: 'I regret to inform you that we are performing scheduled maintenance. We should be back online shortly.',
        suggest: 'Suggested Actions:',
        fix1: 'Please try again.',
        fix2: 'Wait a little while then try again.',
        fix3: 'If this problem persists please contact us ASAP.',
        btnText: 'oops'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}

