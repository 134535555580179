import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";

const smsTour4 = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutorialFourService {

  constructor(private router: Router,) { }


  smsTour4() {
		smsTour4.on('complete', () => {
			this.router.navigate(['/sms/send/single-sms']);
		})
		smsTour4.addSteps([{
			id: 'start',
			title: 'PART4: Send To Campaign Entries',
			text: 'This is part 4/4 of the interactive tutorial covering every component related to sending SMS messages.<br><br>\
			Part 4 will cover Sending Bulk SMS to all unique entries to a selected campaign.<br><br>\
			<mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
			buttons: [
				{action() {return this.next();},text: 'Next'}
			],
		},{
      id: 'step1',
      title: 'Send To Shortcode Campaigns',
      text: 'Send SMS messages by providing the message and selecting one of your campaigns to send to.<br><br>\
      This will send a message to each unique entry for your selected campaign.<br><br>\
      <i>- Perfect for replying to your loyal entrees.</i><br><br>',
      attachTo: {element: '.mainS8', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next'}
      ],
    },{
      id: 'step2',
      title: 'Message Field',
      text: 'First you would enter the message that you would like to send (Similar to Sending To Phone Books).<br><br>\
      <i>Your message may only contain between 1 and 160 characters. Avoid using any special characters in your message.</i><br><br>',
      attachTo: {element: '.sms2', on: 'top'},
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step3',
      title: 'Campaign Select',
			text: 'Then you\'d select one of your campaigns. This is similar to selecting a phone book to send to.<br>\
			The total amount of SMS being sent depends on your entry count.<br><br>',
			attachTo: {element: '.sms3', on: 'top'},
			canClickTarget: false,
      buttons: [
        {action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step4',
      title: 'When To Send',
      text: 'Then you decide if the message should be sent either instantly or at a chosen time and date.<br><br>\
      <i>This is 100% similar to Part 2 and Part 3 - So we are also skipping over it.</i><br><br>',
      attachTo: {element: '.sms4', on: 'top'},
      canClickTarget: false,
      buttons: [
        {action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step5',
      title: 'Add To Send Queue Button',
      text: 'After completing all of the previous steps you need to click on this button to add your SMS to your queue.<br><br>\
      <i>It should be noted that this queue is local and not yet final. It serves as a preview before you finally submit your queue.</i><br><br>\
      <i>Interaction with Add To Send Queue Button is disabled for this step.</i>',
			attachTo: {element: '.sms5', on: 'top'},
			canClickTarget: false,
      buttons: [
        {action() {return this.back();},text: 'Back',},
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step6',
      title: 'Your Message Queue',
      text: 'After adding a message to your queue it will be displayed similar to Part1: Send Single SMS.<br><br>\
      You can review your messages, add more messages, remove single messages, remove entire queue or submit your queue to be sent.<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'step7',
      title: 'Well Done! (4/4)',
      text: 'You have just successfully completed Part 4: Sending To Campaign Entries.<br><br>\
      <i>This concludes Part 4/4 of our tutorial - It got rough at some stages but we pushed through. You are my favorite User :)</i><br><br>\
      <mark>*** Don\'t forget to check out the other 3x in depth SMS tutorials covering Phone Books, Reports and Scheduled SMS respectively.</mark>',
      buttons: [
        {action() {return this.complete();},text: 'Fin'}
      ],
    },
	]);
	
	
	smsTour4.start();
	
		
	}
}
