// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

// **********************LIVE***************************
export const environment = {
  production: true,
  orgId: 'default',
  baseUrl: 'https://webapi.simchat.co.za',
  fileUpURL: 'https://webapi.simchat.co.za/v1/upload/',
  adminUrl: 'https://webapi.simchat.co.za/v1/admin',
  campaignsUrl: 'https://webapi.simchat.co.za/v1/campaigns',
  campaignUrl: 'https://webapi.simchat.co.za/v1/campaign',
  smsInUrl: 'https://webapi.simchat.co.za/v1/sms_in',
  creditsUrl: 'https://webapi.simchat.co.za/v1/credits',
  dashboardUrl: 'https://webapi.simchat.co.za/v1/dashboard/campaigns',
  passwordUrl: 'https://webapi.simchat.co.za/v1/password/forgot/reset',
  liveReadUrl: 'https://webapi.simchat.co.za/v1/campaigns/reader/live',
  liveReadUrl2: 'https://webapi.simchat.co.za/v1/report/live/sms/reply',
  navbarUrl: 'https://webapi.simchat.co.za/v1/navbar',
  phonebooksUrl: 'https://webapi.simchat.co.za/v1/phonebooks',
  randomWinUrl: 'https://webapi.simchat.co.za/v1/campaign/randomwinner',
  smsUrl: 'https://webapi.simchat.co.za/v1/sms',
  usersUrl: 'https://webapi.simchat.co.za/v1/users',
  xlsxReportUrl: 'https://webapi.simchat.co.za/v1/report/xlsx',
  contactUsUrl: 'https://webapi.simchat.co.za/v1/contact/send',
  optoutUrl: 'https://webapi.simchat.co.za/v1/optout'
};


// export const environment = {
//   production: true,
//   baseUrl:        'https://webapi.digichat.co.za',
//   fileUpURL:      'https://webapi.digichat.co.za/v1/upload/',
//   adminUrl:       'https://webapi.digichat.co.za/v1/admin',
//   campaignsUrl:   'https://webapi.digichat.co.za/v1/campaigns',
//   campaignUrl:    'https://webapi.digichat.co.za/v1/campaign',
//   smsInUrl:       'https://webapi.digichat.co.za/v1/sms_in',
//   creditsUrl:     'https://webapi.digichat.co.za/v1/credits',
//   dashboardUrl:   'https://webapi.digichat.co.za/v1/dashboard/campaigns',
//   passwordUrl:    'https://webapi.digichat.co.za/v1/password/forgot/reset',
//   liveReadUrl:    'https://webapi.digichat.co.za/v1/campaigns/reader/live',
//   liveReadUrl2:   'https://webapi.digichat.co.za/v1/report/live/sms/reply',
//   navbarUrl:      'https://webapi.digichat.co.za/v1/navbar',
//   phonebooksUrl:  'https://webapi.digichat.co.za/v1/phonebooks',
//   randomWinUrl:   'https://webapi.digichat.co.za/v1/campaign/randomwinner',
//   smsUrl:         'https://webapi.digichat.co.za/v1/sms',
//   usersUrl:       'https://webapi.digichat.co.za/v1/users',
//   xlsxReportUrl:  'https://webapi.digichat.co.za/v1/report/xlsx',
//   contactUsUrl:   'https://webapi.digichat.co.za/v1/contact/send',
//   optoutUrl:		'https://webapi.digichat.co.za/v1/optout'
// };

// **********************LOCAL***************************
// export const environment = {
//   production: false,
//   orgId: 'default',
//   baseUrl: 'http://localhost:3000',
//   fileUpURL: 'http://localhost:3000/v1/upload/',
//   adminUrl: 'http://localhost:3000/v1/admin',
//   campaignsUrl: 'http://localhost:3000/v1/campaigns',
//   campaignUrl: 'http://localhost:3000/v1/campaign',
//   smsInUrl: 'http://localhost:3000/v1/sms_in',
//   creditsUrl: 'http://localhost:3000/v1/credits',
//   dashboardUrl: 'http://localhost:3000/v1/dashboard/campaigns',
//   passwordUrl: 'http://localhost:3000/v1/password/forgot/reset',
//   liveReadUrl: 'http://localhost:3000/v1/campaigns/reader/live',
//   liveReadUrl2: 'http://localhost:3000/v1/report/live/sms/reply',
//   navbarUrl: 'http://localhost:3000/v1/navbar',
//   phonebooksUrl: 'http://localhost:3000/v1/phonebooks',
//   randomWinUrl: 'http://localhost:3000/v1/campaign/randomwinner',
//   smsUrl: 'http://localhost:3000/v1/sms',
//   usersUrl: 'http://localhost:3000/v1/users',
//   xlsxReportUrl: 'http://localhost:3000/v1/report/xlsx',
//   contactUsUrl: 'http://localhost:3000/v1/contact/send',
//   optoutUrl: 'http://localhost:3000/v1/optout'
// };



/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
