import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';

import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ConfirmComponent } from "../../../modals/other/confirm/confirm.component";
import { ErrorComponent } from '../../../modals/other/error/error.component';

import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { AdminService } from '../../../services/admin.service';
import { LoaderService } from '../../../services/loader.service';


@Component({
  selector: 'app-sms-bundles',
  templateUrl: './sms-bundles.component.html',
  styleUrls: ['./sms-bundles.component.scss']
})
export class SmsBundlesComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  public tabSelected: boolean;
  public submitModify: boolean;
  public submitDelete: boolean;
  public submitAdd: boolean;
  public loading: boolean;
  public loadingfail: boolean;
  
  private resp: any;
  private modifyInfo: any;
  private newBundle: any;

  private smsBundles: Array<any>;
  public optionsSmsPackage: Array<any>;

  private selectedBundle: number;

  public desc2: string;
  public smsNo: string;
  public unitPrice: string;
  public desc: string;

  public modifyBundleForm: FormGroup;
  public selectBundleForm: FormGroup;

  

  constructor(
    private smsBundlesService: AdminService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
    this.tabSelected = false;
    this.submitModify = false;
    this.submitDelete = false;
    this.submitAdd = false;
    this.loadingfail = false;

    this.resp = {}
    this.modifyInfo = {};
    this.newBundle = {};

    this.smsBundles = [];
    this.optionsSmsPackage = [];

    this.selectedBundle = 0;

    this.desc2 = '';
    this.desc = '';
    this.smsNo = '';
    this.unitPrice = '';

    this.loadAllSmsBundles();
    this.modifyBundleForm = this.fb.group({
      smsp_id: ['', Validators.required],
      smsp_desc: ['', [Validators.required, Validators.maxLength(200)]],
      smsp_unitPrice: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^([0-9|.]*)$/)]],
      smsp_smsNo: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^([0-9]*)$/)]],
      smsp_del: [0],
      smsp_desc2: ['', [Validators.required, Validators.maxLength(10), Validators.pattern(/^([0-9|.]*)$/)]]
    });
    this.selectBundleForm = this.fb.group({
      bundle_select: ['', Validators.required]
    });
    this.smsBundleSelected();

    this.optionsSmsPackage = [];
  }

  get s1() {return this.modifyBundleForm.controls; }
  get s2() {return this.selectBundleForm.controls; }

  get smsp_id() {return this.modifyBundleForm.get('smsp_id'); }
  get smsp_desc() {return this.modifyBundleForm.get('smsp_desc'); }
  get smsp_unitPrice() {return this.modifyBundleForm.get('smsp_unitPrice'); }
  get smsp_smsNo() {return this.modifyBundleForm.get('smsp_smsNo'); }
  get smsp_desc2() {return this.modifyBundleForm.get('smsp_desc2'); }

  smsBundleSelected() {
    this.selectBundleForm.get('bundle_select').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
      if ( value === null || value.length === 0) {
      } else {
        this.selectedBundle = value;
        this.getSelectedBundleDetails();
      }
    });
  }

  loadAllSmsBundles() {
    this.smsBundlesService.getAllCreditPackages()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        for (let index = 0; index < this.resp.count; index++) {
            const element = this.resp.rows[index];
            this.smsBundles.push({
              value: index,
              label: element.smsp_smsNo + ' ' + element.smsp_desc2 + ' = R ' + element.smsp_unitPrice.toFixed(2),
            });
          }
        this.optionsSmsPackage = this.smsBundles;
        this.smsBundles = [];
      },
      err => {
        this.loadingfail = true;
      }
    );
  }

  

  getSelectedBundleDetails() {
    this.modifyBundleForm.setValue({
      smsp_id:          this.resp.rows[this.selectedBundle].smsp_id,
      smsp_desc:        this.resp.rows[this.selectedBundle].smsp_desc,
      smsp_unitPrice:   this.resp.rows[this.selectedBundle].smsp_unitPrice,
      smsp_smsNo:       this.resp.rows[this.selectedBundle].smsp_smsNo,
      smsp_del:         this.resp.rows[this.selectedBundle].smsp_del,
      smsp_desc2:       this.resp.rows[this.selectedBundle].smsp_desc2.substring((this.resp.rows[this.selectedBundle].smsp_desc2.indexOf('R') + 1),
                        (this.resp.rows[this.selectedBundle].smsp_desc2.indexOf('/')))
    });
  }

  onSubmitDetails() {
    this.submitModify = true;
    this.submitDelete = false;

    // stop if invalid
    if (this.modifyBundleForm.invalid) {
      this.openModalErrorModifyFail()
      return;
    }

    this.openModalConfirmModify();
  }

  onDeletePackage() {
    this.submitDelete = true;
    this.submitModify = false;

    // stop if invalid
    if (this.selectBundleForm.invalid) {
      this.openModalErrorDeleteFail();
      return;
    }

    this.openModalConfirmDelete();
  }

  updateBundleDetails() {
      this.modifyInfo = this.modifyBundleForm.value;
      this.modifyInfo.smsp_desc2 = '@ R' + this.smsp_desc2.value + '/SMS';
      this.smsBundlesService.updateCreditPackage(this.modifyInfo)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        if(data[0] === 0){
          this.openModalSuccessNoChange();
          this.ngOnInit();
        } else if (data[0] === 1){
          this.openModalSuccessChange();
          this.ngOnInit();
        }
      });
      
    }

  deleteSelectedBundle() {
      this.smsBundlesService.deleteCreditPackage({smsp_id: this.resp.rows[this.selectedBundle].smsp_id})
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
          this.openModalSuccessDelete();
          this.ngOnInit();
      });
  }

  onAddNewBundle() {
    this.submitAdd = true;
    if (this.desc === '' || this.desc2 === '' || this.unitPrice === '' || this.smsNo === '') {
      this.openModalErrorModifyFail()
      return;
    }

    this.openModalConfirmAdd();
  }

  addNewBundle() {
      this.newBundle = {
        smsp_desc: this.desc + ' SMS Messages',
        smsp_unitPrice: this.unitPrice,
        smsp_smsNo: this.smsNo,
        smsp_desc2: '@ R' + this.desc2 + '/SMS'
      };
      this.smsBundlesService.addNewCreditPackage(this.newBundle)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(data => {
        this.openModalSuccessAdd();
        this.ngOnInit();
      });
      
  }

  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
  }

  openModalConfirmModify() {
    this.modalOptions.data = {
      heading: 'Really Update?', 
      content: {
        heading: 'Are you sure you want to save all changes made?',
        amount: 0,
        description: '',
        btnText: 'Confirm!'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.updateBundleDetails();
      } 
    });
  }

  openModalConfirmDelete() {
    this.modalOptions.data = {
      heading: 'Really Delete?', 
      content: {
        heading: 'Are you sure you want to completely Delete the selected package?',
        amount: 0,
        description: '',
        btnText: 'Do It!'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.deleteSelectedBundle();
      } 
    });
  }

  openModalConfirmAdd() {
    this.modalOptions.data = {
      heading: 'Really Add?', 
      content: {
        heading: 'Are you sure you want to create a new package with the details provided?',
        amount: 0,
        description: '',
        btnText: 'Do It!'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.addNewBundle();
      } 
    });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccessNoChange() {
    this.modalOptionsSuccess.data = {
      heading: 'Packaged & Processed!',
      content: {
        heading: 'Your request has been processed - But no changes were made.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessChange() {
    this.modalOptionsSuccess.data = {
      heading: 'Packaged & Processed!',
      content: {
        heading: 'Your request has been processed - All changes have been registered & saved.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Done & Gone!',
      content: {
        heading: 'Your request has been processed - Selected package has been deleted.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessAdd() {
    this.modalOptionsSuccess.data = {
      heading: 'Done & Done!',
      content: {
        heading: 'Your request has been processed - Your package was created with the provided details.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorModifyFail() {
    this.modalOptionsError.data = {
      heading: 'Tsk-Tsk...',
      content: {
        heading: 'Out of the mere 4x fields you have managed to do it wrong...',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that all 4x fields have content.',
        fix2: 'Ensure that all 4x fields contain valid content.',
        fix3: 'Do it right.',
        btnText: 'Sigh'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }


  openModalErrorDeleteFail() {
    this.modalOptionsError.data = {
      heading: 'Can not delete NaN...',
      content: {
        heading: 'If I have to delete nothing and I can\'t because it doesn\'t exist - Did I succeed?',
        suggest: 'Suggested Actions:',
        fix1: 'Indicate which package you want me to delete.',
        fix2: 'Then click on delete after the fact.',
        fix3: 'Have you ever stopped to think for a moment, and then forgot to start again?',
        btnText: 'Yes'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
