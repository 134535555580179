import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { SuccessModalComponent } from '../../modals/other/success-modal/success-modal.component';

@Component({
  selector: 'app-campaign-select-modal',
  templateUrl: './campaign-select-modal.component.html',
  styleUrls: ['./campaign-select-modal.component.scss']
})
export class CampaignSelectModalComponent implements OnInit {

  heading: string;
  contentKeyword: any;
  contentInstant: any;

  constructor(public modalRef: MDBModalRef,    private router: Router,    private modalService: MDBModalService

    ) {}

  ngOnInit() {
  }

  onClickInstantWinner() {
    this.modalRef.hide();
    this.openModalSuccess();
    this.router.navigate(['/contact-us'])
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Please Contact Us!',
      content: {
        heading: '"Why is this happening to me?" I hear you asking. Well due to the complexity and intricate nature of Instant Winner Campaigns you need to reach out to our IT team to make this happen! Sales@SIMCHAT.co.za',
        btnText: 'Will Do!'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

}
