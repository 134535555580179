import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DatePipe } from '@angular/common';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CreditsService } from '../../../services/credits.service';
import { ErrorComponent } from '../../../modals/other/error/error.component';

@Component({
  selector: 'app-purchase-history',
  templateUrl: './purchase-history.component.html',
  styleUrls: ['./purchase-history.component.scss']
})
export class PurchaseHistoryComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  creditHistory: Array<any>;
  tempArr: any;

  // DATE & TIME FORMATS
  pipe = new DatePipe('en-ZA'); // Use your own locale
  // DATE & TIME FORMATS

  headPurchaseHistory = ['Invoice Number', 'Date', 'Person',
  'Package', 'Price (Incl VAT)', 'Denied', 'Reason',
  'Status', 'Status Date'];

  public loading: boolean;
  public loadingfail: boolean;

  constructor(
    private creditService: CreditsService,
    private modalService: MDBModalService
  ) { }

  ngOnInit() {
    this.loading = false;
    this.loadingfail = false;
    this.creditHistory = [];
    this.tempArr = {};
    this.loadCreditHistory();
	}
	
	getColor(status) { 
    switch (status) {
      case 'Denied':
        return 'red';
      case 'Pending':
        return 'black';
      case 'Approved':
        return 'green';
    }
  }

  loadCreditHistory() {
    this.loading = true;
    this.creditService.getCreditHistory()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.tempArr = data;
        for (let index = 0; index < this.tempArr.length; index++) {
          const element = this.tempArr[index];
          const element2 = this.tempArr[index].SmsPack;

          if (element.eft_processed === 1 && element.eft_isRejected === 1) {
            element.eft_processed = 'Denied'
          } else {
            if (element.eft_processed === 1) {
              element.eft_processed = 'Approved'
            } else {
              element.eft_processed = 'Pending'
            }
          }
          if (element.eft_isRejected === 0) {element.eft_isRejected = 'No'; element.eft_reasonRejected = 'N/A'} else {element.eft_isRejected = 'Yes'}
          element.eft_dteCreated = this.pipe.transform(element.eft_dteCreated, 'yyyy-MM-dd HH:mm:ss');
          if (element.eft_dteProcessed !== null) { element.eft_dteProcessed = this.pipe.transform(element.eft_dteProcessed, 'yyyy-MM-dd HH:mm:ss')}
          this.creditHistory[index] = element;
          this.creditHistory[index].smsp_desc = element2.smsp_desc;
          this.creditHistory[index].smsp_unitPrice = element2.smsp_unitPrice;
        }
        this.tempArr = [];
        this.loading = false;
      },
      err => {this.openModalErrorTimeout(); this.loadingfail = true; this.loading = false}
    );
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorTimeout() {
    this.modalOptionsError.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }

 


}
