import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const baseUrl = environment.smsUrl + '/report';

@Injectable({
  providedIn: 'root'
})
export class ReportsSmsService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };


  constructor(private httpClient: HttpClient) {}

  numberLookupReport(content) {
    if (this.token !== null) {
        this.httpOptions = {
          headers: {
            'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
            'Content-Type': 'application/json'
          }
        };
        return this.httpClient.post(baseUrl + '/numberlookup', content, this.httpOptions);
      }return;
}

sentMessagesReport(payload) {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/sent',payload, this.httpOptions);
    }return;
}

sentMessagesTotal() {
  if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl + '/sentTotal', this.httpOptions);
    }return;
}

}
