import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { PhonebookService } from '../../../services/phonebook.service';
import { LoaderService } from '../../../services/loader.service';

// TODO -> FILE UPLOAD CONTACT.

@Component({
  selector: 'app-blacklist-winner',
  templateUrl: './blacklist-winner.component.html',
  styleUrls: ['./blacklist-winner.component.scss']
})
export class BlacklistWinnerComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject<any> = new Subject();

  editContactForm: FormGroup;
  public content: {bl_number: string, bl_name: string, bl_surname: string, bl_date_won: string, bl_id: number};
  public headings: any;
  public title: string;
  public buttonText: any;
  public submitted: boolean;
  public contactDuplicate: boolean;
  public loading: boolean;
  public loadingtext: string;
  private payload: any;
  private payload2: any;
  private resp: any;

  constructor(public modalRef: MDBModalRef, private fb: FormBuilder, private phonebookService: PhonebookService, private modalService: MDBModalService,
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}



  ngOnInit() {
    this.contactDuplicate = false;
    this.resp = {};
    this.payload2 = {};
    this.submitted = false;
    this.loadingtext = '';
    this.editContactForm = this.fb.group({
      bl_number: [this.content.bl_number, [Validators.required, Validators.pattern(/^(0|27)[0-9]{9}$/)]],
      bl_name: [this.content.bl_name, [Validators.required, Validators.maxLength(50)]],
      bl_surname: [this.content.bl_surname, [Validators.required, Validators.maxLength(50)]],
      bl_date_won: [this.content.bl_date_won, [Validators.required, Validators.maxLength(50)]],
    });

    
  }

  get p() {return this.editContactForm.controls; }
  get bl_number() {return this.editContactForm.get('bl_number'); }
  get bl_name() {return this.editContactForm.get('bl_name'); }
  get bl_surname() {return this.editContactForm.get('bl_surname'); }
  get bl_date_won() {return this.editContactForm.get('bl_date_won'); }

  onUpdateContact() {
    this.submitted = true;

    if (this.bl_name.invalid) {return;}
    if (this.bl_surname.invalid) {return;}
    if (this.bl_date_won.invalid) {return;}
    if (this.bl_number.invalid) {return;}

    this.payload = {
      bl_id: this.content.bl_id,
      bl_number: this.bl_number.value,
      bl_name: this.bl_name.value,
      bl_surname: this.bl_surname.value,
      bl_date_won: this.bl_date_won.value,
    }

    this.updateContact()
  }

  updateContact(){
    if(this.content.bl_id !== 0) {
      this.loadingtext = 'Updating Contact...'
    this.phonebookService.editBlacklistWinner(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
      this.action.next(true);
      this.modalRef.hide();  
      }
      )
  } else {
    this.loadingtext = 'Creating Contact...'
    this.phonebookService.addBlacklistWinner(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
      this.action.next(true);
      this.modalRef.hide();  
      })
  }
    
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}
