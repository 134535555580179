import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MDBModalRef, MDBModalService, TabsetComponent } from 'ng-uikit-pro-standard';
import {ExcelService} from '../../../services/excel.service';
import { DatePipe } from '@angular/common';

import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';

import { ReportsSmsService } from "../../../services/reports-sms.service";

@Component({
  selector: 'app-number-lookup',
  templateUrl: './number-lookup.component.html',
  styleUrls: ['./number-lookup.component.scss']
})
export class NumberLookupComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('staticTabs', { static: true }) staticTabs: TabsetComponent
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private payload: any;
  private resp: any;

  pipe = new DatePipe('en-ZA'); // Use your own locale

  public loading: boolean;

  private resultArr: Array<any>;

  public submitted: boolean;
	public resultsReceived: boolean;
	public numberLookedUp: string;

  public smsAmount: number;
  
  numberLookupForm: FormGroup;

  
  headSms = ['Cell Number', 'Message', 'Date processed', 'Status','Reply','Reply Message','Date Replied'];

  constructor(
    private reportService: ReportsSmsService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private excelService:ExcelService
  ) { }

  ngOnInit() {
		this.numberLookedUp = '';
    this.loading = false;
    this.payload = {};
    this.resp = {};
    this.resultArr = [];
    this.resultsReceived = false;
    this.submitted = false;    
    this.smsAmount = 0;

    this.numberLookupForm = this.fb.group({
      sms_cell: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^(\+[0-9]|[0-9])*$/)]]
    });
  }

  get p() {return this.numberLookupForm.controls; }
  get sms_cell() {return this.numberLookupForm.get('sms_cell'); }



  getSelectedOutSms() {
  
    this.resultArr = [];
    this.submitted = true;

    if (this.sms_cell.value === '') {
      this.openModalErrorInputNumber();
      return
    }

    if (this.numberLookupForm.invalid) {
      this.openModalErrorInput();
      return;
    }
  
    this.payload = {
      sms_cell: this.sms_cell.value,
      sms_cell2: '+27' + this.sms_cell.value.substring(1),
      sms_cell3: '27' + this.sms_cell.value.substring(1)
		}
		this.numberLookedUp = this.sms_cell.value;
    this.loading = true;
    this.reportService.numberLookupReport(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        for (let index = 0; index < this.resp.length; index++) {
          const element = this.resp[index];
          this.resultArr[index] = element;
        }
        this.smsAmount = this.resultArr.length
        if (this.resultArr.length === 0) {
          this.openModalErrorNoHits();
          this.resultsReceived = false;
        } else {
          this.openModalSuccess();
          this.resultsReceived = true;
          for (let index = 0; index < this.resultArr.length; index++) {
            const element = this.resultArr[index];
            this.resultArr[index]['Date Processed'] = this.pipe.transform(element['Date Processed'], 'yyyy-MM-dd HH:mm:ss');
            this.resultArr[index]['Reply Date'] = this.pipe.transform(element['Reply Date'], 'yyyy-MM-dd HH:mm:ss');
          }
        }
        this.loading = false;
      },
      err => {this.openModalErrorTimeout(); this.loading = false}
    ); 
  }

  onExport() {
    this.excelService.exportAsExcelFile(this.resultArr, this.numberLookedUp + '_Number_Lookup_Report');
  }

  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorInputNumber() {
    this.modalOptionsErrorInput.data = {
      heading: 'In search of nothing...',
      content: {
        heading: 'You gave me no number to search for. - If I search for nothing, and I don\'t find anything, does this mean I was successful?',
        suggest: 'Suggested Actions:',
        fix1: 'Please provide a number to lookup.',
        fix2: '',
        fix3: '',
        btnText: 'My bad'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorInput() {
    this.modalOptionsErrorInput.data = {
      heading: 'That number looks odd...',
      content: {
        heading: 'There seems to be an issue with the cellphone field.',
        suggest: 'Suggested Actions:',
        fix1: 'Start number with "0" NOT "+27".',
        fix2: 'Ensure that number is exactly 10 digits long.',
        fix3: 'Avoid using spaces or any non-numeric characters.',
        btnText: 'Fix Number'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }


  openModalErrorNoHits() {
    this.modalOptionsErrorInput.data = {
      heading: 'Not a single message to be found...',
      content: {
        heading: 'I looked everywhere... No luck. This number does not have any inbound or outbound traffic.',
        suggest: 'Suggested Actions:',
        fix1: 'Make sure you have provided the correct number.',
        fix2: 'Send a message to this contact and get a conversation going.',
        fix3: 'Ask this number to send you a message.',
        btnText: '=('
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Update Failed Successfully...',
      content: {
        heading: 'I seem to have lost you there for a moment...',
        suggest: 'Suggested Actions:',
        fix1: 'Please check your network connectivity.',
        fix2: 'Try to refresh your browser.',
        fix3: 'Then try again.',
        btnText: 'it happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'I found something!',
      content: {
        heading: 'This is everything I could scrape together. - Considering you only gave a number to go on.',
        btnText: 'Lets see'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
  }
}