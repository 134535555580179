import { Injectable } from '@angular/core';
import Shepherd from "shepherd.js";
import { Router } from "@angular/router";

const manageCampaignTour = new Shepherd.Tour ({
  defaultStepOptions: {
    cancelIcon: {
      enabled: true
    },
    classes: 'shadow-md bg-purple-dark',
    scrollTo: { behavior: 'smooth', block: 'center' },
  }, 
  confirmCancel: false,
  // confirmCancelMessage: '',
  exitOnEsc: false,
  keyboardNavigation: false,
  useModalOverlay: true,
});

@Injectable({
  providedIn: 'root'
})
export class TutManageCampService {

  constructor(private router: Router,) { }

  manageCampaignTour() {

		// manageCampaignTour.on('complete', () => {
    //   this.router.navigate(['/dashboard/campaign']);
		// })
		// manageCampaignTour.on('cancel', () => {
		// 	this.router.navigate(['/dashboard/campaign']);
		// })

    manageCampaignTour.addSteps([{
      id: 'start',
      title: 'Managing Your Campaigns',
      text: 'This is an interactive tutorial and will cover every aspect of effectively managing your own campaign.<br><br>\
      As always I have appointed myself to be you little helper. At the end you will be particularly useful when managing existing campaigns.<br><br>\
      <mark>- At some steps I will ask you to interact with the page before we can move on. These interactive actions will be highlighted like this</mark>',
      buttons: [
        {action() {return this.next();},text: 'Let\'s Begin'}
      ],
    },{
      id: 'intro',
      title: 'Campaign Management',
      text: 'Here you can manage completely manage all of your existing campaigns.<br><br>',
      attachTo: {element: '.mainS18', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'select',
      title: 'Select a Campaign',
      text: 'Firstly you need to select the campaign that you want to manage.<br><br>\
      <mark>Please select one of your campaigns then click on "Next".</mark>',
      attachTo: {element: '.selectCamp', on: 'bottom'},
      modalOverlayOpeningPadding: 30,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'stepCheck',
      title: 'Verifying...',
      text: 'Just checking if you selected a campaign.<br><br>\
      If you did not select a campaign you will have to.<br><br>\
      If you did select a campaign - Lets get into it!',
      canClickTarget: false,
      buttons: [
        {action() {if(document.getElementById('campFlag') === null) {
          return this.show('stepError')} else {
          return this.show('stepStart');  
          }},text: 'Next'}
      ],
    },{
      id: 'stepError',
      title: 'Back to the drawing board',
      text: 'Please select a campaign before clicking on "Next" this time...<br><br>',
      canClickTarget: false,
      buttons: [
        {action() {return this.show('select');},text: 'Start again',}
      ],
    },{
      id: 'stepStart',
      title: 'Marvelous!',
      text: 'You have successfully selected a campaign to manage.<br><br>\
      So at the top here you will see the currently selected campaign\'s name.',
      attachTo: {element: '.campHead', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'stepEnd',
      title: 'Fin.',
      text: 'For various technical & nerdy reasons I have thought it best to do this tutorial a little differently...<br><br>\
      The tutorial for managing campaigns is split into 2x parts. Of which you just finished the first part.<br><br>\
      <i>Bear with me...</i>',
      canClickTarget: false,
      buttons: [
        {action() {return this.next();},text: 'Next',}
      ],
    },{
      id: 'end',
      title: 'See that Pink Icon?',
      text: 'Just a little to the left of your campaign\'s name there is this bright pink icon.<br><br>\
      I will have to get going now - Family stuff - So I will hand you over to my brother.<br><br>\
      <mark>Once you click on "Bye" and I leave - Please click on this icon to resume this tutorial.</mark>',
      attachTo: {element: '.campHeada', on: 'bottom'},
      canClickTarget: false,
      buttons: [
        {action() {return this.complete();},text: 'Bye',}
      ],
    }
  ]);

  
  manageCampaignTour.start();

    
  }
}
