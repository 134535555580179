import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const baseUrl = environment.campaignUrl + '/report';
const baseUrl2 = environment.campaignsUrl

@Injectable({
  providedIn: 'root'
})
export class ReportsCampaignsService {

  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };


  constructor(private httpClient: HttpClient) { }

  getUniqueCountBetween(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/uniquebetween', payload, this.httpOptions);
    } return;
  }

  getSelectedCampaignStats(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl, payload, this.httpOptions);
    } return;
  }


  getUserCampaigns() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl2, this.httpOptions);
    } return;
  }

  getUserInstantCampaigns() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.get(baseUrl2 + '/instant', this.httpOptions);
    } return;
  }

  getCampaignEntries(content) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/received', content, this.httpOptions);
    } return;
  }


  getSelectedCampaignTicketUsage(camp_id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/tickets', camp_id, this.httpOptions);
    } return;
  }


  getSelectedTicketNumberInfo(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/ticket', payload, this.httpOptions);
    } return;
  }


  getSelectedValidEntries(camp_id) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.httpClient.post(baseUrl + '/valid', camp_id, this.httpOptions);
    } return;
  }
}
