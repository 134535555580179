import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil, windowTime } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { CreditsService } from "../../../../services/credits.service";
import { ConfirmComponent } from '../../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../../modals/other/error/error.component';
import { LoaderService } from '../../../../services/loader.service';

@Component({
  selector: 'app-credit-alert',
  templateUrl: './credit-alert.component.html',
  styleUrls: ['./credit-alert.component.scss']
})
export class CreditAlertComponent implements OnInit, OnDestroy {

	private ngUnsubscribe: Subject<any> = new Subject();
	modalRef: MDBModalRef;
	
	public submitted: boolean;
	public creditWarningForm: FormGroup;
	public loading: boolean;

	public uc_getCreditWarning: boolean;
	public threshold: number;
	public resp: any;
	public resp2: any;
	public payload: any;

  constructor(
		// Add service here
		private creditService: CreditsService,
		private fb: FormBuilder,
    private modalService: MDBModalService,
		private loaderService: LoaderService,
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}

  ngOnInit() {
		this.submitted = false;
		this.uc_getCreditWarning = false;
		this.threshold = 0;
		this.resp = {};
		this.resp2 = {};

		this.creditWarningForm = this.fb.group({
			uc_creditWarningThreshold: [0, [Validators.required, Validators.max(99999), Validators.min(0)]]
		})

		this.loadCreditWarningDetails();
  }

	get p() {return this.creditWarningForm.controls; }
	get uc_creditWarningThreshold() {return this.creditWarningForm.get('uc_creditWarningThreshold'); }

	loadCreditWarningDetails() {
		this.creditService.getUserCreditAlertDetails()
		.pipe(takeUntil(this.ngUnsubscribe))
		.subscribe(
			data => {
				this.resp = data;
				this.uc_getCreditWarning = this.resp.details.uc_getCreditWarning;
				this.creditWarningForm.setValue({
					uc_creditWarningThreshold: this.resp.details.uc_creditWarningThreshold
				})
			},
      err => {}
		)
	}

	onUpdateLowCreditBalance() {

		if (this.uc_getCreditWarning) {

			if(this.creditWarningForm.invalid) {
				this.openModalErrorInput();
				return
			}

			this.payload = {
				uc_getCreditWarning: this.uc_getCreditWarning,
				uc_creditWarningThreshold: this.uc_creditWarningThreshold.value
			}

		} else {
			this.payload = {
				uc_getCreditWarning: false,
				uc_creditWarningThreshold: 0
			}
		}

		this.creditService.updateUserCreditAlertDetails(this.payload)
			.pipe(takeUntil(this.ngUnsubscribe))
			.subscribe(
				data => {
					this.resp2 = data;
					this.openModalSuccessAdd();
					this.ngOnInit();
				},
				err => {window.alert('update request failed')}
			);

		
	}


	modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

	openModalErrorInput() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'Please ensure that you have provided a valid threshold amount.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the threshold amount is a numeric value.',
        fix2: 'Ensure that the threshold amount does not exceed 99999.',
        fix3: 'Ensure that the threshold amount is not negative.',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
	}
	
	modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccessAdd() {
    this.modalOptionsSuccess.data = {
      heading: 'Low Credit Alert Updated',
      content: {
        heading: 'I have just applied the changes you have made to your Low Credit Balance Alert.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

	ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}
}
