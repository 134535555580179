import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { LoaderService } from '../../../services/loader.service';
import { NavbarService } from '../../../services/navbar.service';
import { CreditsService } from "../../../services/credits.service";
import { ActivatedRoute, Router } from '@angular/router';

import { TemplateSelectModalComponent } from "../../../modals/edit/template-select-modal/template-select-modal.component";
import { SmsSendSingleService } from '../../../services/sms-send-single.service';
import { DatePipe } from '@angular/common';

const gsmChar = [
  '@', '£', '$', '¥', 'è', 'é', 'ù', 'ì',
  'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å',
  'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ',
  'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É',
  '!', '"', '#', '¤', '%', '&', '\'',
  '(', ')', '*', '+', ',', '-', '.', '/',
  '0', '1', '2', '3', '4', '5', '6', '7',
  '8', '9', ':', ';', '<', '=', '>', '?',
  '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G',
  'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
  'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§',
  '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
  'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
  'p', 'q', 'r', 's', 't', 'u', 'v', 'w',
  'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à',
  '^', '{', '}', '\\', '[', ']', '~', '|', '€', ' '
]

@Component({
  selector: 'app-single-sms',
  templateUrl: './single-sms.component.html',
  styleUrls: ['./single-sms.component.scss']
})
export class SingleSmsComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private messageContent: any = [];
  private msgProcessText: string;
  public specialChars: number;
  public specialCharArr: Array<any>;
  public hasSpecialChars: boolean;
  public amountOfNewLines: number;



  sendSingleSms: FormGroup;
  scheduledForm: FormGroup;
  submitted;
  resp;
  public hours;
  public minutes;
  public creditBal: number;
  public amountToRemove: number;

  public loading: boolean;

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };

  scheduled;
  messagesInQueue;
  now = Date.now();
  pipe = new DatePipe('en-ZA'); // Use your own locale
  dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  public day = this.pipe.transform(this.now, 'yyyy-MM-dd');

  messageQueue: Array<any> = [];

  headMessageQueue = ['Cell Number', 'Message', 'Date to Send', ''];


  constructor(
    private smsService: SmsSendSingleService,
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private router: Router,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
    private navbarService: NavbarService,
    private creditService: CreditsService
  ) { this.loaderService.isLoading.subscribe((v) => { this.loading = v; }); }


  ngOnInit() {
    this.amountOfNewLines = 0;
    this.hasSpecialChars = false;
    this.specialCharArr = [];
    this.specialChars = 0;
    this.msgProcessText = '';
    this.creditBal = 0;
    this.amountToRemove = 0;
    this.loading = false;
    this.messageQueue = [];
    this.scheduled = false;
    this.messagesInQueue = 0;
    this.submitted = false;
    this.resp = {};
    this.hours = 0;
    this.minutes = 0;
    this.myDatePickerOptions.disableUntil.year = (new Date()).getFullYear();
    this.myDatePickerOptions.disableUntil.month = (new Date()).getMonth() + 1;
    this.myDatePickerOptions.disableUntil.day = (new Date()).getDate() - 1;
    this.sendSingleSms = this.fb.group({
      sms_msg: ['', [Validators.required, Validators.maxLength(306)]],
      sms_cell: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern(/^([0-9]|[0-9])*$/)]]
    });

    this.scheduledForm = this.fb.group({
      sms_dateToSend: ['', Validators.required],
      sms_timeToSend: ['', Validators.required]
    });

    this.getCreditBalance();
  }


  get p() { return this.sendSingleSms.controls; }
  get s() { return this.scheduledForm.controls; }
  get gsms_msg() { return this.sendSingleSms.get('sms_msg'); }
  get gsms_cell() { return this.sendSingleSms.get('sms_cell'); }
  get sms_dateToSend() { return this.scheduledForm.get('sms_dateToSend'); }
  get sms_timeToSend() { return this.scheduledForm.get('sms_timeToSend'); }

  getCreditBalance() {
    this.navbarService.getNavCredits()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          this.creditBal = this.resp.uc_amt;
        }
      );
  }

  deductCreditBalance() {
    this.creditService.updateCreditBalance({ deduct: this.messagesInQueue })
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.openModalSuccess();
          this.ngOnInit();
        }
      )
  }

  onSubmitSend() {
    this.submitted = true;

    if (this.hasSpecialChars) {
      this.openModalErrorInputSpecialChars();
      return
    }

    if (this.gsms_msg.invalid) {
      this.openModalErrorInputMessage();
      return;
    }

    if (this.gsms_cell.invalid) {
      this.openModalErrorInputNumber();
      return;
    }

    if (this.scheduledForm.invalid && this.scheduled === true) {
      this.openModalErrorSchedule();
      return;
    }

    if (this.scheduled === true && this.day === this.sms_dateToSend.value) {
      this.hours = (new Date()).getHours();
      this.minutes = (new Date()).getMinutes();
      if (this.hours < 10) { this.hours = '0' + this.hours }
      if (this.minutes < 10) { this.minutes = '0' + this.minutes }
      if ((this.sms_timeToSend.value <= this.hours + ':' + this.minutes)) {
        this.openModalErrorInvalidTime();
        return;
      }


    }


    this.onAddToQueue();
  }

  onAddToQueue() {
    // window.scrollTo(0,document.body.scrollHeight)
    window.scroll(0, 1000);
    if ((this.gsms_msg.value.length + this.amountOfNewLines) > 160) {
      this.messagesInQueue += 2;
    } else {
      this.messagesInQueue++;
    }
    if (this.scheduled) {
      this.scheduledDate = this.sms_dateToSend.value + '\t' + this.sms_timeToSend.value + ':00';

    } else {
      this.scheduledDate = this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
    }
    this.messageQueue.push({
      sms_cell: this.gsms_cell.value,
      sms_msg: this.gsms_msg.value,
      sms_dteCreated: this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss'),
      sms_dteTime2Send: this.scheduledDate,
      contact_id: '',
      camp_id: '',
      sms_batchName: ''
    });
    this.sendSingleSms.reset();
    this.scheduledForm.reset();
    this.scheduled = false;
    this.submitted = false;
    this.hours = 0;
    this.amountOfNewLines = 0;
  }

  onSubmitQueue() {
    this.messageContent = this.messageQueue;

    this.sendSMS();

  }

  sendSMS() {
    if (this.creditBal < this.messagesInQueue) {
      this.openModalCredits();
      return;
    } else {
      this.smsService.sendSmsToQueue(this.messageContent)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          this.resp = data;
          if (!this.resp) {
            this.deductCreditBalance();
          }
          window.scroll(0, 0);
          this.submitted = false;
        }, err => {
        });
    }


  }

  onDeleteQueue() {
    this.messageQueue = [];
    this.messagesInQueue = 0;
    window.scroll(0, 0);
    this.openModalSuccessDelete();
  }

  onDeleteOne(id: any) {
    if (this.messageQueue[id].sms_msg.length > 160) {
      this.messagesInQueue -= 2;
    } else {
      this.messagesInQueue--;
    }
    this.messageQueue.splice(id, 1);
    this.openModalSuccessDeleteOne();
  }

  checkForNewLines() {
    this.amountOfNewLines = this.gsms_msg.value.split('\n').length - 1 || 0;

    this.sendSingleSms.controls["sms_msg"].setValidators([Validators.required, Validators.maxLength(306 - this.amountOfNewLines)])
    this.sendSingleSms.controls["sms_msg"].updateValueAndValidity();
  }


  onBlurCheckForSpecialCharacters() {
    this.checkForNewLines();
    this.specialChars = 0;
    this.specialCharArr = [];
    this.hasSpecialChars = false;
    if (this.gsms_msg.value) {
      // if (this.gsms_msg.value.length > 160) {
      //   return;
      // }
      this.specialChars = 0;
      this.specialCharArr = [];
      this.msgProcessText = this.gsms_msg.value;
      this.hasSpecialChars = false;

      for (let index = 0; index < this.msgProcessText.length; index++) {
        const element = this.msgProcessText.charAt(index);

        if (gsmChar.indexOf(element) === -1) {
          this.specialChars++
          this.specialCharArr.push(element)
          this.hasSpecialChars = true;
        }
      }

      if (this.hasSpecialChars) {
        this.openModalErrorInputSpecialChars()
      }
    }

  }


  openTemplateModal() {

    this.modalRef = this.modalService.show(TemplateSelectModalComponent, {
      backdrop: true,
      keyboard: true,
      focus: true,
      show: false,
      ignoreBackdropClick: false,
      class: '',
      containerClass: '',
      animated: true
    });
    this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result) {
          this.gsms_msg.setValue(result);
          this.checkForNewLines();
        }
      });
  }


  modalOptions = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', amount: 0, description: '', btnText: '' }
    }
  }

  openModalConfirmSend() {
    this.modalOptions.data = {
      heading: 'Are you ready to send?',
      content: {
        heading: 'Are you sure you want to submit a total of ',
        amount: this.messagesInQueue,
        description: ' message(s)?',
        btnText: 'Send them all'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result) {
          this.onSubmitQueue();
        }
      });
  }

  openModalConfirmDelete() {
    this.modalOptions.data = {
      heading: 'Really remove all messages?',
      content: {
        heading: 'Are you sure you want to remove a total of ',
        amount: this.messagesInQueue,
        description: ' message(s) from your queue?',
        btnText: 'Remove them all'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result) {
          this.onDeleteQueue();
        }
      });
  }

  openModalConfirmDeleteSingleMessage(id: any) {
    this.modalOptions.data = {
      heading: 'Really remove this message?',
      content: {
        heading: 'Are you sure you want to remove this ',
        amount: 1,
        description: ' message from your queue?',
        btnText: 'Remove it'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
    this.modalRef.content.action
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((result: any) => {
        if (result) {
          this.onDeleteOne(id)
        }
      });
  }

  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', btnText: '' }
    }
  }

  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Done and done!',
      content: {
        heading: 'Your message queue has been successfully submitted to be sent - Look at you taking care of business.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }

  openModalSuccessDelete() {
    this.modalOptionsSuccess.data = {
      heading: 'Taken care of!',
      content: {
        heading: 'Your message queue has been successfully removed - Sleeping with the fishes.',
        btnText: 'Good riddance'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
    this.ngOnInit();
  }

  openModalSuccessDeleteOne() {
    this.modalOptionsSuccess.data = {
      heading: 'Taken care of!',
      content: {
        heading: 'That meddlesome message has been successfully removed - No questions asked.',
        btnText: 'Good riddance'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  modalOptionsErrorInput = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: '' }
    }
  }

  openModalErrorInputMessage() {
    this.modalOptionsErrorInput.data = {
      heading: 'Your message looks wrong...',
      content: {
        heading: 'There seems to be an issue with your message field.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide at least 1 character.',
        fix2: 'Ensure the message does not exceed 306 characters.',
        fix3: 'Avoid using any special characters.',
        btnText: 'Fix Message'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorInputSpecialChars() {
    this.modalOptionsErrorInput.data = {
      heading: 'Special Characters Detected!',
      content: {
        heading: 'Whoa there cowboy! My Special character meter is going crazy. I can see at least ' + this.specialChars + 'x special character.',
        suggest: 'What now?',
        fix1: 'Special Characters: ' + this.specialCharArr + '.',
        fix2: 'Please remove or replace these culprits at your discretion before you can continue.',
        fix3: 'These characters cause numerous inconveniences most notable halving your character limit.',
        btnText: 'wow'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorInputNumber() {
    this.modalOptionsErrorInput.data = {
      heading: 'That number looks wrong...',
      content: {
        heading: 'There seems to be an issue with the cellphone field.',
        suggest: 'Suggested Actions:',
        fix1: 'Start number with "0" NOT "+27".',
        fix2: 'Ensure that number is exactly 10 digits long.',
        fix3: 'Avoid using spaces or any non-numeric characters.',
        btnText: 'Fix Number'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorSchedule() {
    this.modalOptionsErrorInput.data = {
      heading: 'No scheduled date selected...',
      content: {
        heading: 'There seems to be an issue with the scheduled date and time fields',
        suggest: 'Suggested Actions:',
        fix1: 'Select a date from the date selector field.',
        fix2: 'Select a time from the time selector field.',
        fix3: 'The time selected should not be in the past.',
        btnText: 'Fix schedule'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalCredits() {
    this.amountToRemove = this.messagesInQueue - this.creditBal;
    this.modalOptionsErrorInput.data = {
      heading: 'Not enough credits...',
      content: {
        heading: 'It seems that your current credit balance of ' + this.creditBal + ' is not sufficient to facilitate the ' + this.messagesInQueue + ' messages in your queue.',
        suggest: 'Suggested Actions:',
        fix1: 'Try removing at least ' + this.amountToRemove + ' messages from your queue.',
        fix2: 'Top up your credit balance by purchasing a credit bundle.',
        fix3: 'Smile at strangers, laugh at yourself, and know that you’re free to start over.',
        btnText: 'Got it'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorTimeout() {
    this.modalOptionsErrorInput.data = {
      heading: 'Well this is embarrassing...',
      content: {
        heading: 'There was an error processing your request and was unsuccessful.',
        suggest: 'Suggested Actions:',
        fix1: 'Network: Check your internet connection and try again.',
        fix2: 'Internal: It might be our fault, and we are working on it.',
        fix3: 'Feel free to contact us if this error persists.',
        btnText: 'It happens'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  openModalErrorInvalidTime() {
    this.modalOptionsErrorInput.data = {
      heading: 'Great Scott!!!',
      content: {
        heading: 'We are firm believers in time travel. To prevent the space-time continuum from collapsing we can not allow you to schedule messages to be sent to the past.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that the selected schedule time is after the current time.',
        fix2: '"Your future is whatever you make it. So make it a good one."',
        fix3: 'Keep calm and try again.',
        btnText: 'Thanks Doc'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  ngAfterViewInit() {
  }
}
