import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { IMyOptions } from 'ng-uikit-pro-standard';
import { DatePipe } from '@angular/common';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { ActivatedRoute, Router } from '@angular/router';
import { LoaderService } from '../../../services/loader.service';
import { NavbarService } from '../../../services/navbar.service';
import { CreditsService } from "../../../services/credits.service";
import { SmsSendSingleService } from '../../../services/sms-send-single.service';
import { PhonebookService } from '../../../services/phonebook.service';
import { TemplateSelectModalComponent } from "../../../modals/edit/template-select-modal/template-select-modal.component";

const gsmChar = [
  '@', '£', '$', '¥', 'è', 'é', 'ù', 'ì',
  'ò', 'Ç', '\n', 'Ø', 'ø', '\r', 'Å', 'å',
  'Δ', '_', 'Φ', 'Γ', 'Λ', 'Ω', 'Π', 'Ψ',
  'Σ', 'Θ', 'Ξ', 'Æ', 'æ', 'ß', 'É',
  '!', '"', '#', '¤', '%', '&', '\'',
  '(', ')', '*', '+', ',', '-', '.', '/',
  '0', '1', '2', '3', '4', '5', '6', '7',
  '8', '9', ':', ';', '<', '=', '>', '?',
  '¡', 'A', 'B', 'C', 'D', 'E', 'F', 'G',
  'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
  'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W',
  'X', 'Y', 'Z', 'Ä', 'Ö', 'Ñ', 'Ü', '§',
  '¿', 'a', 'b', 'c', 'd', 'e', 'f', 'g',
  'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o',
  'p', 'q', 'r', 's', 't', 'u', 'v', 'w',
  'x', 'y', 'z', 'ä', 'ö', 'ñ', 'ü', 'à',
  '^','{','}','\\','[',']','~','|','€', ' '
]

@Component({
  selector: 'app-from-phonebook',
  templateUrl: './from-phonebook.component.html',
  styleUrls: ['./from-phonebook.component.scss']
})
export class FromPhonebookComponent implements OnInit, AfterViewInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  private messageContent: Array<any>;
  private tempArr: Array<any>;
  private tempcontacts: Array<any>;
  private contactList: Array<any>;
  private ContactsDetail: Array<any>;
  public phonebooks: Array<any>;

  private msgProcessText: string;
  public specialChars: number;
  public specialCharArr: Array<any>;
  public hasSpecialChars: boolean;

  private contactsGet: any;
  private resp: any;
  private resp2: any;
  private resp3: any;

  public loading: boolean;
  public loadingfail: boolean;
  public flag123: boolean;

  public phonebookSelected2: boolean;

  public submitted: boolean;
  public scheduled: boolean;
  public phonebookSelected: boolean;

  public creditBal: number;
  public amountToRemove: number;

  public hours: any;
  public minutes: any;
  public messagesInQueue: number;
  public amountOfNewLines: number;

  sendToPhonebookForm: FormGroup;
  scheduledForm: FormGroup;

  public myDatePickerOptions: IMyOptions = {
    todayBtnTxt: 'Today',
    clearBtnTxt: 'Clear',
    closeBtnTxt: 'Close',
    closeAfterSelect: true,
    editableDateField: false,
    disableUntil: { year: 0, month: 0, day: 0 }
  };



  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  dateCreated = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  scheduledDate = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
  public day = this.pipe.transform(this.now, 'yyyy-MM-dd');

  
  optionsPhonebooks: Array<any> = [];
  optionsContacts: Array<any> = [];

  messageQueue: Array<any> = [];
 
   headMessageQueue = ['Cell Number', 'Name', 'Message', 'Date to Send', 'Message Parts', ''];

  constructor(
    private smsService: SmsSendSingleService,
    private phonebookService: PhonebookService,
    private fb: FormBuilder,
    private actRoute: ActivatedRoute,
    private router: Router,
    private modalService: MDBModalService,
    private loaderService: LoaderService,
    private navbarService: NavbarService,
    private creditService: CreditsService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
    this.amountOfNewLines = 0;
    this.hasSpecialChars = false;
    this.specialCharArr = [];
    this.specialChars = 0;
    this.msgProcessText = '';
		this.phonebookSelected = false;
		this.phonebookSelected2 = false;
    this.creditBal = 0;
    this.amountToRemove = 0;
    this.loading = false;
    this.loading = false;
    this.loadingfail = false;
    this.flag123 = false;
    this.messageContent = [];
    this.tempArr = [];
    this.tempcontacts = [];
    this.contactList = [];
    this.ContactsDetail = [];
    this.phonebooks = [];

    this.contactsGet = {};
    this.resp = {};
    this.resp2 = {};
    this.resp3 = {};

    this.submitted = false;
    this.scheduled = false;

    this.hours = 0;
    this.minutes = 0;
    this.messagesInQueue = 0;
    this.myDatePickerOptions.disableUntil.year = (new Date()).getFullYear();
    this.myDatePickerOptions.disableUntil.month = (new Date()).getMonth() + 1;
    this.myDatePickerOptions.disableUntil.day = (new Date()).getDate() - 1;
    
    this.getPhoneBooks();

    this.sendToPhonebookForm = this.fb.group({
      sms_msg: ['', [Validators.required, Validators.maxLength(306)]],
      usercgroups: ['', Validators.required],
      usercontacts: ['', Validators.required]
    });

    this.scheduledForm = this.fb.group({
      sms_batchName: ['', [Validators.required, Validators.maxLength(250)]],
      sms_dateToSend: ['', Validators.required],
      sms_timeToSend: ['', Validators.required]
    });

    this.getContacts();
    this.getSelectedContacts();
    this.getCreditBalance();
}

  get p() {return this.sendToPhonebookForm.controls; }
  get s() {return this.scheduledForm.controls; }
  get gsms_msg() {return this.sendToPhonebookForm.get('sms_msg'); }
  get sms_dateToSend() {return this.scheduledForm.get('sms_dateToSend'); }
  get sms_timeToSend() {return this.scheduledForm.get('sms_timeToSend'); }
  get sms_batchName() {return this.scheduledForm.get('sms_batchName'); }


  getCreditBalance() {
    this.navbarService.getNavCredits()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        this.creditBal = this.resp.uc_amt;
      }
    );
  }

  deductCreditBalance() {
    this.creditService.updateCreditBalance({deduct: this.messagesInQueue})
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.openModalSuccess();
        this.ngOnInit();
      }
    )
  }


  getPhoneBooks() {
  this.phonebookService.getUserPhonebooks()
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(
		data => {
			this.resp = data;
			for (let index = 0; index < this.resp.phonebook.count; index++) {
        const element = this.resp.phonebook.rows[index];
				this.tempArr.push({
            value: element.ucgoup_id,
            label: element.ucgoup_name,
				});
			}
      this.optionsPhonebooks = this.tempArr;
		}, err => {
      this.loadingfail = true;
    }
	);
  }

  getContacts() {
    this.sendToPhonebookForm.get('usercgroups').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
      this.contactsGet = {ucgroup_id: value};
      if ( value === null || value.length === 0) {
                this.optionsContacts = [];
								this.sendToPhonebookForm.get('usercontacts').setValue('');
								this.phonebookSelected2 = false;
					} else
					{
            this.phonebookService.getPhonebooksContacts(this.contactsGet)
            .pipe(takeUntil(this.ngUnsubscribe))
      			.subscribe(
        			data => {
                this.resp2 = data;
          			for (let index = 0; index < this.resp2.contacts.count; index++) {
            			const element = this.resp2.contacts.rows[index];
                  this.tempcontacts[index] = {
                    value: element.contact_id,
                    label: element.contact_cell + ' ' + element.contact_col1 + ' ' + element.contact_col2
                  }
								}
								this.phonebookSelected2 = true;
                this.optionsContacts = this.tempcontacts;
                this.tempcontacts = [];
        }, err => {
        });
			}
		});
  }
  
  getSelectedContacts() {
    this.sendToPhonebookForm.get('usercontacts').valueChanges
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (value) => {
      this.contactList = [];
      if ( value === null || value.length === 0) {
      } else
      {
        this.phonebookSelected = true;
      for (let index = 0; index < value.length; index++) {
        const element = value[index];
        this.contactList[index] = element;
      }
      }
    });
  }

  onSubmitSend() {
    this.submitted = true;

    if (this.hasSpecialChars) {
      this.openModalErrorInputSpecialChars();
      return
    }

    // stop if invalid
    if (this.gsms_msg.invalid) {
      this.openModalErrorInputMessage();
      return;
    }

    if (this.sendToPhonebookForm.invalid) {
      this.openModalErrorInputPhonebook();
      return;
    }

    if (this.sms_batchName.invalid && this.scheduled === true) {
      this.openModalErrorBatchName();
      return;
    }

    if (this.scheduledForm.invalid && this.scheduled === true) {
      this.openModalErrorSchedule();
      return;
    }

    if (this.scheduled === true && this.day === this.sms_dateToSend.value) {
      this.hours = (new Date()).getHours();
      this.minutes = (new Date()).getMinutes();
      if (this.hours < 10) {this.hours = '0' + this.hours}
      if (this.minutes < 10) {this.minutes = '0' + this.minutes}
      if ((this.sms_timeToSend.value <= this.hours + ':' + this.minutes)) {
        this.openModalErrorInvalidTime();
        return;
      }
  }
    

    this.getSelectedDetails();
  }

  getSelectedDetails() {
    this.phonebookService.getSelectedContactDetails(this.contactList)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp3 = data;
        if (this.scheduled) {
          this.scheduledDate = this.sms_dateToSend.value + '\t' + this.sms_timeToSend.value + ':00';
        } else {
          this.scheduledDate = this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss');
        }
        for (let index = 0; index < this.resp3.details.count; index++) {
					const element = this.resp3.details.rows[index];
					let sms_name = "";
					if (element.contact_col1 !== null && element.contact_col2 !== null) {
						sms_name = element.contact_col1 + ' ' + element.contact_col2;
					} else if(element.contact_col1 === null && element.contact_col2 !== null) {
						sms_name = element.contact_col2 
					} else if (element.contact_col2 === null && element.contact_col1 !== null) {
						sms_name = element.contact_col1
					} 
          this.ContactsDetail.push({
            sms_name:         sms_name,
            sms_cell:         element.contact_cell,
            sms_msg:          this.gsms_msg.value,
            sms_dteCreated:   this.pipe.transform(Date.now(), 'yyyy-MM-dd HH:mm:ss'),
            sms_dteTime2Send: this.scheduledDate,
            contact_id:       element.contact_id,
            camp_id:          '',
            sms_batchName:    this.sms_batchName.value
          });
          if ((this.gsms_msg.value.length + this.amountOfNewLines) > 160) {
            this.messagesInQueue += 2;
          } else {
            this.messagesInQueue++;
          }
        }
        // this.messagesInQueue = this.ContactsDetail.length;
        this.onAddToQueue();
      }
    );
  }

onAddToQueue() {	
  window.scroll(0,1000);
  this.phonebookSelected = false;
  this.sendToPhonebookForm.reset();
  this.scheduledForm.reset();
	this.scheduled = false;
  this.submitted = false;
  this.amountOfNewLines = 0;
}

onSubmitQueue() {
    this.messageContent = this.ContactsDetail;
    this.sendSMS();
}

sendSMS() {
  if (this.creditBal < this.messagesInQueue) {
    this.openModalCredits();
    return;
  } else {
  this.ContactsDetail = [];
  this.flag123 = true;
  this.smsService.sendSmsToQueue(this.messageContent)
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe(data => {
    this.resp = data;
    if (!this.resp){
      this.deductCreditBalance();
    }
    this.flag123 = false;
    this.submitted = false;
    window.scroll(0,document.body.scrollHeight);
  });
}
}

onDeleteQueue() {
    this.ContactsDetail = [];
    this.messagesInQueue = 0;
    window.scroll(0,0);
    this.openModalSuccessDelete();
}

onDeleteOne(id: any) {
  if (this.ContactsDetail[id].sms_msg.length > 160) {
    this.messagesInQueue-=2;
  } else {
    this.messagesInQueue--;
  }
  this.ContactsDetail.splice(id, 1);
  this.openModalSuccessDeleteSingle();
}

checkForNewLines() {
    this.amountOfNewLines = this.gsms_msg.value.split('\n').length - 1 || 0;

    this.sendToPhonebookForm.controls["sms_msg"].setValidators([Validators.required, Validators.maxLength(306 - this.amountOfNewLines)])
    this.sendToPhonebookForm.controls["sms_msg"].updateValueAndValidity();
  }

onBlurCheckForSpecialCharacters() {
  this.checkForNewLines();
  this.specialChars = 0;
  this.specialCharArr = [];
  this.hasSpecialChars = false;
  if (this.gsms_msg.value) {
    // if (this.gsms_msg.value.length > 160) {
    //   return;
    // }
    this.specialChars = 0;
    this.specialCharArr = [];
    this.msgProcessText = this.gsms_msg.value;
    this.hasSpecialChars = false;
    
    for (let index = 0; index < this.msgProcessText.length; index++) {
      const element = this.msgProcessText.charAt(index);

      if (gsmChar.indexOf(element) === -1) {
        this.specialChars++
        this.specialCharArr.push(element)
        this.hasSpecialChars = true;
      }
    }
    
    if (this.hasSpecialChars) {
      this.openModalErrorInputSpecialChars()
    }
  }
  
}

openTemplateModal() {
    
	this.modalRef = this.modalService.show(TemplateSelectModalComponent, {
		backdrop: true,
		keyboard: true,
		focus: true,
		show: false,
		ignoreBackdropClick: false,
		class: '',
		containerClass: '',
		animated: true
});
	this.modalRef.content.action
	.pipe(takeUntil(this.ngUnsubscribe))
	.subscribe( (result: any) => { 
		if (result) {
			this.gsms_msg.setValue(result);
      this.checkForNewLines();
		} 
	});
}


modalOptions = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: 'modal-dialog modal-notify modal-info',
  containerClass: 'modal fade',
  animated: true,
  data: {
      heading: '',
      content: { heading: '', amount: 0, description: '', btnText: ''}
  }
}

openModalConfirmSend() {
  this.modalOptions.data = {
    heading: 'Are you ready to send?', 
    content: {
      heading: 'Are you sure you want to submit a total of ',
      amount: this.messagesInQueue,
      description: ' message(s)?',
      btnText: 'Send them all'
    }
  }
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
  this.modalRef.content.action
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe( (result: any) => { 
    if (result) {
      this.onSubmitQueue();
    } 
  });
}

openModalConfirmDelete() {
  this.modalOptions.data = {
    heading: 'Really remove all messages?', 
    content: {
      heading: 'Are you sure you want to remove a total of ',
      amount: this.messagesInQueue,
      description: ' message(s) from your queue?',
      btnText: 'Remove them all'
    }
  }
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
  this.modalRef.content.action
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe( (result: any) => { 
    if (result) {
      this.onDeleteQueue();
    } 
  });
}

openModalConfirmDeleteSingleMessage(id: any) {
  this.modalOptions.data = {
    heading: 'Really remove this message?', 
    content: {
      heading: 'Are you sure you want to remove this ',
      amount: 1,
      description: ' message from your queue?',
      btnText: 'Remove it'
    }
  }
  this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptions)
  this.modalRef.content.action
  .pipe(takeUntil(this.ngUnsubscribe))
  .subscribe( (result: any) => { 
    if (result) {
      this.onDeleteOne(id)
    } 
  });
}

modalOptionsSuccess = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: 'modal-dialog modal-notify modal-success',
  containerClass: 'modal fade',
  animated: true,
  data: {
      heading: '',
      content: { heading: '', btnText: ''}
  }
}

openModalSuccess() {
  this.modalOptionsSuccess.data = {
    heading: 'Done and done!',
    content: {
      heading: 'Your message queue has been successfully submitted to be sent - Look at you taking care of business.',
      btnText: 'Thank you'
    }
  }
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
}

openModalSuccessDelete() {
  this.modalOptionsSuccess.data = {
    heading: 'Taken care of!',
    content: {
      heading: 'Your message queue has been successfully removed - Sleeping with the fishes.',
      btnText: 'Good riddance'
    }
  }
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess);
  this.ngOnInit();
}

openModalSuccessDeleteSingle() {
  this.modalOptionsSuccess.data = {
    heading: 'Taken care of!',
    content: {
      heading: 'That meddlesome message has been successfully removed - No questions asked.',
      btnText: 'Good riddance'
    }
  }
  this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
}

modalOptionsErrorInput = {
  backdrop: true,
  keyboard: true,
  focus: true,
  show: false,
  ignoreBackdropClick: false,
  class: 'modal-dialog modal-notify modal-danger',
  containerClass: 'modal fade',
  animated: true,
  data: {
      heading: '',
      content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
  }
}

openModalErrorInputMessage() {
  this.modalOptionsErrorInput.data = {
    heading: 'Your message looks wrong...',
    content: {
      heading: 'There seems to be an issue with your message field.',
      suggest: 'Suggested Actions:',
      fix1: 'Provide at least 1 character.',
      fix2: 'Ensure the message does not exceed 160 characters.',
      fix3: 'Avoid using any special characters.',
      btnText: 'Fix Message'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalErrorInputSpecialChars() {
  this.modalOptionsErrorInput.data = {
    heading: 'Special Characters Detected!',
    content: {
      heading: 'Whoa there cowboy! My Special character meter is going crazy. I can see at least ' + this.specialChars + 'x special character.',
      suggest: 'What now?',
      fix1: 'Special Characters: ' + this.specialCharArr + '.',
      fix2: 'Please remove or replace these culprits at your discretion before you can continue.',
      fix3: 'These characters cause numerous inconveniences most notable halving your character limit.',
      btnText: 'wow'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalErrorInputPhonebook() {
  this.modalOptionsErrorInput.data = {
    heading: 'You have not selected a contact...',
    content: {
      heading: 'There seems to be an issue with your contact field.',
      suggest: 'Suggested Actions:',
      fix1: 'Select at least 1 phonebook from the phone books dropdown.',
      fix2: 'Select at least 1 contact from the contacts dropdown.',
      fix3: 'If no phone books are listed you need to create one first and add contacts to it.',
      btnText: 'Fix problem'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalErrorBatchName() {
  this.modalOptionsErrorInput.data = {
    heading: 'Your batch name looks wrong...',
    content: {
      heading: 'There seems to be an issue with the batch name field.',
      suggest: 'Suggested Actions:',
      fix1: 'Ensure that you have provided a batch name.',
      fix2: 'A batch name is only required if messages are scheduled.',
      fix3: '',
      btnText: 'Fix Batch Name'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalErrorSchedule() {
  this.modalOptionsErrorInput.data = {
    heading: 'No scheduled date selected...',
    content: {
      heading: 'There seems to be an issue with the scheduled date and time fields',
      suggest: 'Suggested Actions:',
      fix1: 'Select a date from the date selector field.',
      fix2: 'Select a time from the time selector field.',
      fix3: 'The time selected should not be in the past.',
      btnText: 'Fix schedule'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalCredits() {
  this.amountToRemove = this.messagesInQueue - this.creditBal;
  this.modalOptionsErrorInput.data = {
    heading: 'Not enough credits...',
    content: {
      heading: 'It seems that your current credit balance of ' + this.creditBal + ' is not sufficient to facilitate the ' + this.messagesInQueue + ' messages in your queue.',
      suggest: 'Suggested Actions:',
      fix1: 'Try removing at least ' + this.amountToRemove + ' messages from your queue.',
      fix2: 'Top up your credit balance by purchasing a credit bundle.',
      fix3: 'Smile at strangers, laugh at yourself, and know that you’re free to start over.',
      btnText: 'Got it'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalErrorTimeout() {
  this.modalOptionsErrorInput.data = {
    heading: 'Well this is embarrassing...',
    content: {
      heading: 'There was an error processing your request and was unsuccessful.',
      suggest: 'Suggested Actions:',
      fix1: 'Network: Check your internet connection and try again.',
      fix2: 'Internal: It might be our fault, and we are working on it.',
      fix3: 'Feel free to contact us if this error persists.',
      btnText: 'It happens'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

openModalErrorInvalidTime() {
  this.modalOptionsErrorInput.data = {
    heading: 'Great Scott!!!',
    content: {
      heading: 'We are firm believers in time travel. To prevent the space-time continuum from collapsing we can not allow you to schedule messages to be sent to the past.',
      suggest: 'Suggested Actions:',
      fix1: 'Ensure that the selected schedule time is after the current time.',
      fix2: '"Your future is whatever you make it. So make it a good one."',
      fix3: 'Keep calm and try again.',
      btnText: 'Thanks Doc'
    }
  }
  this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsErrorInput)
}

ngOnDestroy(): any {
  this.ngUnsubscribe.next();
  this.ngUnsubscribe.complete();
}

ngAfterViewInit() {
 
}


}
