import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';
import { ConfirmComponent } from '../../../../modals/other/confirm/confirm.component';
import { SuccessModalComponent } from '../../../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../../../modals/other/error/error.component';
import { LoaderService } from '../../../../services/loader.service';
import { UserService } from '../../../../services/user.service';
import { Sms2emailModalComponent } from "../../../../modals/sms2email/sms2email-modal/sms2email-modal.component";

@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit, OnDestroy {

  private ngUnsubscribe: Subject<any> = new Subject();
  modalRef: MDBModalRef;

	public modalOptions: any;
  email2smsForm: FormGroup;
  public submitted: boolean;
  public loading: boolean;
  public loadingfail: boolean;

  public emailArr: Array<any> = [];
  added: boolean;
  resp: any;

  constructor(
    private email2smsService: UserService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
		private loaderService: LoaderService,
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}

		ngOnInit() {
			this.submitted = false;
			this.added = false;
			this.resp = {};
			this.modalOptions = {};
	
			this.email2smsForm = this.fb.group({
				Email_Add: ['', [Validators.required, Validators.email, Validators.maxLength(100)]]
			})
	
			this.loadEmail2SMS();
		}

		get p() {return this.email2smsForm.controls; }
  get Email_Add() {return this.email2smsForm.get('Email_Add'); }

  loadEmail2SMS() {
    this.email2smsService.getEmail2SMS()
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
       
       
        
      },
      err => {this.loadingfail = true}
    );
  }


  onAddEmail() {
    if (this.email2smsForm.invalid) {
      this.openModalErrorInput();
      return;
    }

    this.email2smsService.updateEmail2SMS({Email_Add:this.Email_Add.value}) 
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.resp = data;
        if (this.resp.success) {
          this.openModalSuccessAdd(this.Email_Add.value)
          this.emailArr = this.resp.email
          this.email2smsForm.reset();
        } else {
          this.openModalErrorTaken(this.Email_Add.value)
          this.email2smsForm.reset();
        }
      },
      err => {this.loadingfail = true}
    );
	}


  modalOptionsConfirm = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-info',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', amount: 0, description: '', btnText: ''}
    }
  }

  onDeleteOneConfirm(id) {
    this.modalOptionsConfirm.data = {
      heading: 'Really Remove This Email Address?', 
      content: {
        heading: 'You are about to remove this email address from your registered list. ',
        amount: 0,
        description: ' This is a permanent action.',
        btnText: 'Really Remove It'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
        this.onDeleteOne(id);
      } 
    });
  }

  onDeleteOne(id) {
    this.email2smsService.deleteEmail2SMS({e2s_id: this.emailArr[id].e2s_id, Email_Add: this.emailArr[id].Email_Add})
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
        this.openModalSuccessRemove(this.emailArr[id].Email_Add);
        this.emailArr.splice(id, 1);
      },
      err => {

      }
    )
	}
	
	onRequestActivation() {
		this.modalOptions = {
			backdrop: true,
			keyboard: true,
			focus: true,
			show: false,
			ignoreBackdropClick: true,
			class: 'modal-dialog modal-notify modal-info',
			containerClass: 'modal fade overflow-auto',
			animated: true
		}
		this.openModal();
	}


	openModal() {
		this.modalRef = this.modalService.show(Sms2emailModalComponent, this.modalOptions);

		this.modalRef.content.action.subscribe( (result: any) => { 
			if (result === true) {
			window.alert('accepted')
			} else {
				return
			}
		 });
	}

	onOpenModalCancel() {
    this.modalOptionsConfirm.data = {
      heading: 'Are you absolutely sure?', 
      content: {
        heading: 'You are about to cancel your Email2SMS service subscription. You will no longer be able to use this service if you proceed. ',
        amount: 0,
        description: '***Please note if there is usage for the current calendar month on your account, you will be billed the monthly SLA of R25.00 per email address with usage.',
        btnText: 'Cancel It'
      }
    }
    this.modalRef = this.modalService.show(ConfirmComponent, this.modalOptionsConfirm)
    this.modalRef.content.action
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe( (result: any) => { 
      if (result) {
				this.onCancelSubscription();
      } 
    });
	}
	
	onCancelSubscription() {
		this.email2smsService.cancelEmail2SMSActivation()
		.pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
				this.resp = data;
				this.ngOnInit();
      },
      err => {
				return
			}
		);
	}

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }

  openModalErrorInput() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'Please ensure that you have provided a valid email address to add.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide an email address.',
        fix2: 'Ensure that it is valid.',
        fix3: 'Then try again.',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }


  openModalErrorTaken(email) {
    this.modalOptionsError.data = {
      heading: 'Conflicted...',
      content: {
        heading: 'It seems like the email address "' + email + '" that you tried to add is already registered for this service.',
        suggest: 'Suggested Actions:',
        fix1: 'Provide a different email address.',
        fix2: 'Verify if this email address isn\'t already in your list.',
        fix3: 'Contact us if all else fails.',
        btnText: 'okay'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }

  openModalSuccessAdd(email) {
    this.modalOptionsSuccess.data = {
      heading: 'Email Address Registered',
      content: {
        heading: 'I have added ' + email + ' to your registered list.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  openModalSuccessRemove(email) {
    this.modalOptionsSuccess.data = {
      heading: 'Email Address Removed',
      content: {
        heading: 'I have removed ' + email + ' from your registered list.',
        btnText: 'Thank you'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
	}
	
	ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
}

}