import { Component, OnInit, OnDestroy, ViewChild, ElementRef, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { Subject, interval } from "rxjs";
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { takeUntil } from "rxjs/operators";
import { DatePipe } from '@angular/common';
import { MDBModalRef, MDBModalService, BaseChartDirective } from 'ng-uikit-pro-standard';
import { ErrorComponent } from '../../../modals/other/error/error.component';
import { LoaderService } from '../../../services/loader.service';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ReportsCampaignsService } from "../../../services/reports-campaigns.service";
import { CampaignService } from '../../../services/campaign.service';
import { Chart } from 'chart.js';
const counter = interval(300);

@Component({
  selector: 'app-campaign-report',
  templateUrl: './campaign-report.component.html',
  styleUrls: ['./campaign-report.component.scss']
})

export class CampaignReportComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('hourChart', { static: false }) hourChart: ElementRef;
  @ViewChild('timeslotChart', { static: false }) timeslotChart: ElementRef;
  @ViewChild('daysOfWeekChart', { static: false }) daysOfWeekChart: ElementRef;
  @ViewChild('weeklyChart', { static: false }) weeklyChart: ElementRef;
  @ViewChildren(BaseChartDirective) chartList: QueryList<BaseChartDirective>;
  @ViewChild('networkChart', { static: false }) networkChart: ElementRef;



  private ngUnsubscribe: Subject<any> = new Subject();
  private unsubCounter: Subject<any> = new Subject();
  modalRef: MDBModalRef;

  campaignForm: FormGroup;
  optionsCampaigns: Array<any>;

  public scale = 0.65;
  campaign: { camp_id: number };
  public showKeyword: boolean;
  public showOptKeyword: boolean;
  public loaded: boolean;
  public loading: boolean;
  public loadingfail: boolean;
  public selected: boolean;
  public userName: string;
  public totalCount: number;
  public totalUnique: number;
  public dailyAve: number;
  public weeklyAve: number;
  public dateStamp: any;
  public keyArrCount: Array<any>;
  public optKeyArrCount: Array<any>;
  public fileNameToSave: string;
  public payload: any;
  private resp: any;
  private uniqueResp: any;
  private tempArr: Array<any>;
  public campaignDetail: any;
  public networkArr: Array<any>;
  private selectedCampaignId: any;
  private keyTop5Arr: Array<any>;
  private top5DaysArr: Array<any>;
  private top5DaysArrCount: Array<any>;
  public campaignType: string;
  public campaignStatus: string;
  pipe = new DatePipe('en-ZA'); // Use your own locale
  now = Date.now();
  startdate;
  enddate;
  nowdate;
  public statusText: string;
  private thebrowser = this.detectBrowserName();
  private msWait = 1000;
  private myWeeklyUniqueCounts: Array<any>;

  public headNetworks = ['Month', 'MTN', 'Vodacom', 'CellC', 'TelkomM', 'Other', 'Total'];

  public status = [
    'Starting up report generator....',
    'Starting up report generator........',
    'Starting up report generator............',
    'Starting up report generator................',
    'Starting up report generator................started!',
    'Crunching some numbers',
    'Crunching some numbers....',
    'Crunching some numbers........',
    'Crunching some numbers............',
    'Crunching some numbers................',
    'Crunching some numbers................crunched!',
    'Stopping to smell the roses',
    'Stopping to smell the roses....',
    'Stopping to smell the roses........',
    'Stopping to smell the roses............',
    'Stopping to smell the roses................',
    'Stopping to smell the roses................hhhhmmm!',
    'You sure have a lot of entries',
    'You sure have a lot of entries....',
    'You sure have a lot of entries........',
    'You sure have a lot of entries............',
    'You sure have a lot of entries................',
    'You sure have a lot of entries................WOW!',
  ]

  // CHART TYPES
  public hourlyType: string = 'line';
  public timeslotType: string = 'doughnut';
  public dayOfWeekType: string = 'bar';
  public weeklyType: string = 'bar';
  public monthlyType: string = 'bar';
  public keywordType: string = 'horizontalBar';
  public dailyType: string = 'line';
  public optKeywordType: string = 'horizontalBar';
  public networkType: string = 'doughnut';
  public top5DaysType: string = 'bar';
  /////////////////////////////////////////////////////////////////////////////////////

  // CHART DATASETS
  public hourlyDatasets: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Hourly Message Totals' }
  ];
  public networkDatasets: Array<any> = [
    { data: [0, 0, 0, 0, 0], label: 'Network Message Totals' }
  ];
  public timeslotDatasets: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0, 0], label: 'Time Slot Message Totals' }
  ];
  public dayOfWeekDatasets: Array<any> = [
    { data: [0, 0, 0, 0, 0, 0, 0], label: 'Day of the week Message Totals' }
  ];
  public weeklyDatasets: Array<any> = [
    { data: [], label: 'Total Messages', categoryPercentage: 0.95, barPercentage: 1.0 },
    { data: [], label: 'Unique Messages', categoryPercentage: 0.95, barPercentage: 1.0 }
  ];
  public monthlyDatasets: Array<any> = [
    { data: [], label: 'Monthly Message Totals' }
  ];
  public keywordDatasets: Array<any> = [
    { data: [], label: 'Keyword Message Totals' }
  ];
  public optKeywordDatasets: Array<any> = [
    { data: [], label: 'Keyword Message Totals' }
  ];
  public dailyDatasets: Array<any> = [
    {
      data: [], label: 'Total Messages', fill: false,
      datalabels: { display: true, anchor: 'end', align: 'end', offset: 10, rotation: -90, font: { family: 'ZonaBold', size: 13, weight: 'bold' } }
    },

    {
      data: [], label: 'Unique Messages', fill: false,
      datalabels: { display: false }
    }
  ];
  public top5DaysDatasets: Array<any> = [
    { data: [], label: 'Top 5 Days' }
  ]

  public pageChunk: Array<any> = [];
  /////////////////////////////////////////////////////////////////////////////////////

  // CHART LABELS
  public hourlyLabels: Array<any> = [
    '00:00', '01:00', '02:00', '03:00', '04:00', '05:00', '06:00', '07:00', '08:00', '09:00', '10:00', '11:00', '12:00', '13:00'
    , '14:00', '15:00', '16:00', '17:00', '18:00', '19:00', '20:00', '21:00', '22:00', '23:00'
  ];
  public timeslotLabels: Array<any> = ['06:00 - 09:00', '09:00 - 12:00', '12:00 - 15:00', '15:00 - 18:00', '18:00 - 21:00',
    '21:00 - 00:00'];
  public dayOfWeekLabels: Array<any> = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  public weeklyLabels: Array<any> = [];
  public monthlyLabels: Array<any> = [];
  public keywordLabels: Array<any> = [];
  public optKeywordLabels: Array<any> = [];
  public dailyLabels: Array<any> = [];
  public networkLabels: Array<any> = ['Vodacom', 'CellC', 'MTN', 'Telkom', 'Other'];
  public top5DaysLabels: Array<any> = [];
  /////////////////////////////////////////////////////////////////////////////////////

  // CHART COLORS
  public stoufColors: Array<any> = ['rgba(113,76,140, 0.7)', 'rgba(255,220,0, 0.7)', 'rgba(40,153,213, 0.7)', 'rgb(162,197,29,0.7)', 'rgba(229,6,127, 0.7)',
    'rgba(255, 166, 0, 0.7)'];

  public allColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(3, 169, 244, 0.7)', 'rgba(244, 67, 54, 0.7)', 'rgba(233, 30, 99, 0.7)', 'rgba(156, 39, 176, 0.7)', 'rgba(63, 81, 181, 0.7)', 'rgba(0, 188, 212, 0.7)',
        'rgba(0, 150, 136, 0.7)', 'rgba(76, 175, 80, 0.7)', 'rgba(205, 220, 57, 0.7)', 'rgba(255, 235, 59, 0.7)', 'rgba(255, 152, 0, 0.7)', 'rgba(121, 85, 72, 0.7)',
        'rgba(158, 158, 158, 0.7)', 'rgba(96, 125, 139, 0.7)', 'rgba(0, 0, 0, 0.7)', 'rgba(62, 69, 81, 0.7)',
        'rgba(3, 169, 244, 0.7)', 'rgba(244, 67, 54, 0.7)', 'rgba(233, 30, 99, 0.7)', 'rgba(156, 39, 176, 0.7)', 'rgba(63, 81, 181, 0.7)', 'rgba(0, 188, 212, 0.7)',
        'rgba(0, 150, 136, 0.7)', 'rgba(76, 175, 80, 0.7)', 'rgba(205, 220, 57, 0.7)', 'rgba(255, 235, 59, 0.7)', 'rgba(255, 152, 0, 0.7)', 'rgba(121, 85, 72, 0.7)',
        'rgba(158, 158, 158, 0.7)', 'rgba(96, 125, 139, 0.7)', 'rgba(0, 0, 0, 0.7)', 'rgba(62, 69, 81, 0.7)'
      ],
      borderColor: [
        'rgba(3, 169, 244, 0.3)', 'rgba(244, 67, 54, 0.3)', 'rgba(233, 30, 99, 0.3)', 'rgba(156, 39, 176, 0.3)', 'rgba(63, 81, 181, 0.3)', 'rgba(0, 188, 212, 0.3)',
        'rgba(0, 150, 136, 0.3)', 'rgba(76, 175, 80, 0.3)', 'rgba(205, 220, 57, 0.3)', 'rgba(255, 235, 59, 0.3)', 'rgba(255, 152, 0, 0.3)', 'rgba(121, 85, 72, 0.3)',
        'rgba(158, 158, 158, 0.3)', 'rgba(96, 125, 139, 0.3)', 'rgba(0, 0, 0, 0.3)', 'rgba(62, 69, 81, 0.3)',
        'rgba(3, 169, 244, 0.3)', 'rgba(244, 67, 54, 0.3)', 'rgba(233, 30, 99, 0.3)', 'rgba(156, 39, 176, 0.3)', 'rgba(63, 81, 181, 0.3)', 'rgba(0, 188, 212, 0.3)',
        'rgba(0, 150, 136, 0.3)', 'rgba(76, 175, 80, 0.3)', 'rgba(205, 220, 57, 0.3)', 'rgba(255, 235, 59, 0.3)', 'rgba(255, 152, 0, 0.3)', 'rgba(121, 85, 72, 0.3)',
        'rgba(158, 158, 158, 0.3)', 'rgba(96, 125, 139, 0.3)', 'rgba(0, 0, 0, 0.3)', 'rgba(62, 69, 81, 0.3)'
      ],
      borderWidth: 2
    }
  ]

  public doughnutColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(40,153,213, 0.7)', 'rgba(162,197,29, 0.7)', 'rgb(255,166,0,0.7)', 'rgba(229,6,127, 0.7)',
        'rgba(113,76,140, 0.7)', 'rgba(255,220,0, 0.7)'
      ],
      borderColor: [
        'white'
      ],
      borderWidth: 2
    }
  ]

  public networkColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(229,6,127, 0.7)', 'rgba(255,166,0, 0.7)', 'rgba(113,76,140, 0.7)', 'rgba(40,153,213, 0.7)', 'rgba(162,197,29,0.7)'
      ],
      borderColor: [
        'white'
      ],
      borderWidth: 2
    }
  ]

  public dayOfWeekColors: Array<any> = [
    {
      backgroundColor: [
        'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)'
      ],
      borderColor: [
        'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)', 'rgba(162,197,29, 0.7)'
      ],
      borderWidth: 2
    }
  ]
  public monthlyColors: Array<any> = [
    {
      backgroundColor: 'rgba(40,153,213, 0.7)',
      borderColor: 'rgba(40,153,213, 0.7)',
      borderWidth: 2,
    }
  ];
  public hourlyColors: Array<any> = [
    {
      backgroundColor: 'rgba(105, 0, 132, .2)',
      borderColor: 'rgba(200, 99, 132, .7)',
      borderWidth: 2,
    }
  ];
  public dailyColors: Array<any> = [
    {
      backgroundColor: 'rgba(40,153,213, 0.7)',
      borderColor: 'rgba(40,153,213, 0.7)',
      borderWidth: 4,
    },
    {
      backgroundColor: 'rgba(229,6,127, 0.7)',
      borderColor: 'rgba(229,6,127, 0.7)',
      borderWidth: 4,
    }
  ];
  public weeklyColors: Array<any> = [
    { backgroundColor: 'rgba(229, 6, 127, 0.7)' },
    { backgroundColor: 'rgba(40,153,213, 0.7)' }
  ]
  public keywordColors: Array<any> = [
    {
      backgroundColor: 'rgba(40,153,213, 0.7)',
      borderColor: 'rgba(40,153,213, 0.7)',
      borderWidth: 2,
    }
  ]
  public optKeywordColors: Array<any> = [
    {
      backgroundColor: 'rgba(40,153,213, 0.7)',
      borderColor: 'rgba(40,153,213, 0.7)',
      borderWidth: 2,
    }
  ]
  public top5DaysColors: Array<any> = [
    {
      backgroundColor: 'rgba(162,197,29, 0.7)',
      borderColor: 'rgba(162,197,29, 0.7)',
      borderWidth: 2,
    }
  ]
  /////////////////////////////////////////////////////////////////////////////////////
  public HourlyOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Hourly Message Totals' },
    layout: { padding: { right: 50 } },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Hour of Day', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { beginAtZero: true, fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };

  public pieOptions: any = {
    legend: {
      position: 'bottom',
      labels: {
        fontFamily: 'ZonaBold',
        fontSize: 14,
        padding: 20
      }
    },
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Time Slot Message Totals' },
    plugins: {
      datalabels: {
        color: 'white',
        font: {
          size: 14,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };
  public NetworkOptions: any = {
    legend: {
      position: 'bottom',
      labels: {
        fontFamily: 'ZonaBold',
        fontSize: 14,
        padding: 20
      }
    },
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Network Message Totals' },
    plugins: {
      datalabels: {
        color: 'white',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold',
        }
      }
    }
  };
  public DayOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Day Of The Week Message Totals' },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Day of Week', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };
  public WeekOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Weekly Message Totals' },
    legend: {
      position: 'bottom',
      labels: {
        fontFamily: 'ZonaBold',
        fontSize: 14,
        padding: 10
      }
    },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Weeks (7-Day intervals)', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }

    }
  };

  public MonthOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Monthly Message Totals' },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Month of Year', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };

  public KeywordOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Keyword Message Totals' },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Keyword', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };
  public OptKeywordOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Optional Keyword Message Totals' },
    scales: {
      xAxes: [{
        offset: true,
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Keyword', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        display: false,
        anchor: 'end',
        align: 'end',
        font: {
          size: 13,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };
  public DailyOptions: any = {
    legend: {
      position: 'bottom',
      labels: {
        fontFamily: 'ZonaBold',
        fontSize: 14,
        padding: 10
      }
    },
    elements: { point: { radius: 0 } },
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 26, fontColor: '#000', padding: 50, text: 'Daily Message Totals' },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold', minRotation: 90, maxRotation: 90 },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Day of Year', fontStyle: 'bold', padding: 20 }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        display: false,
      },
    }
  }

  public Top5DaysOptions: any = {
    responsive: true,
    title: { display: true, fontFamily: 'ZonaBold', fontSize: 24, fontColor: '#000', padding: 20, text: 'Top 5 Days' },
    scales: {
      xAxes: [{
        ticks: { fontFamily: 'ZonaBold' },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Date', fontStyle: 'bold' }
      }],
      yAxes: [{
        ticks: { fontFamily: 'ZonaBold', beginAtZero: true, display: true },
        scaleLabel: { fontFamily: 'ZonaBold', fontSize: 18, display: true, labelString: 'Message Total', fontStyle: 'bold' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'end',
        font: {
          size: 12,
          weight: 'bold',
          family: 'ZonaBold'
        }
      }
    }
  };

  constructor(
    private reportsCampaigns: ReportsCampaignsService,
    private campaignService: CampaignService,
    private fb: FormBuilder,
    private modalService: MDBModalService,
    private router: Router,
    private route: ActivatedRoute,
    private loaderService: LoaderService
  ) { this.loaderService.isLoading.subscribe((v) => { this.loading = v; }); }


  ngOnInit() {
    this.fileNameToSave = '';
    this.loading = false;
    this.loadingfail = false;
    this.loaded = false;
    this.showKeyword = false;
    this.showOptKeyword = false;
    this.selected = false;
    this.userName = '';
    this.totalCount = 0;
    this.totalUnique = 0;
    this.keyArrCount = [];
    this.top5DaysArrCount = [];
    this.optKeyArrCount = [];
    this.payload = {};
    this.resp = {};
    this.tempArr = [];
    this.campaignDetail = {};
    this.networkArr = [];
    this.selectedCampaignId = 0;
    this.statusText = 'Starting up report generator';
    this.campaignType = '';
    this.campaignStatus = '';
    this.campaignForm = this.fb.group({
      camp_id: ['', Validators.required]
    });

    this.hourlyDatasets = [{ data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0], label: 'Hourly Message Totals' }]
    this.timeslotDatasets = [{ data: [0, 0, 0, 0, 0, 0, 0, 0], label: 'Time Slot Message Totals' }]
    this.dayOfWeekDatasets = [{ data: [0, 0, 0, 0, 0, 0, 0], label: 'Day of the week Message Totals' }]
    this.weeklyDatasets = [{ data: [], label: 'Total Messages', categoryPercentage: 0.95, barPercentage: 1.0 },
    { data: [], label: 'Unique Messages', categoryPercentage: 0.95, barPercentage: 1.0 }]
    this.monthlyDatasets = [{ data: [], label: 'Monthly Message Totals' }]
    this.keywordDatasets = [{ data: [], label: 'Keyword Message Totals' }]
    this.optKeywordDatasets = [{ data: [], label: 'Keyword Message Totals' }]
    this.dailyDatasets = [{
      data: [], label: 'Total Messages', fill: false,
      datalabels: { display: true, anchor: 'end', align: 'end', offset: 10, rotation: -90, font: { family: 'ZonaBold', size: 13, weight: 'bold' } }
    },
    {
      data: [], label: 'Unique Messages', fill: false,
      datalabels: { display: false }
    }
    ]
    this.networkDatasets = [{ data: [0, 0, 0, 0, 0], label: 'Network Message Totals' }];
    this.top5DaysDatasets = [{ data: [], label: 'Top 5 Days' }]

    this.weeklyLabels = [];
    this.monthlyLabels = [];
    this.keywordLabels = [];
    this.dailyLabels = [];
    this.optKeywordLabels = [];
    this.top5DaysLabels = [];
    this.myWeeklyUniqueCounts = [];

    this.campaign = {
      camp_id: this.route.snapshot.params['camp_id']
    };

    this.loadAllCampaigns();
    this.selectedCampaign();

    if (this.campaign.camp_id !== undefined) {
      this.campaignForm.setValue({
        camp_id: this.campaign.camp_id
      });
    }
  }

  loadAllCampaigns() {
    this.campaignService.getUserCampaigns()
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          let optionsIndex = 0;
          for (let index = 0; index < this.resp.count; index++) {
            const element = this.resp.rows[index];
            if (element.camp_reportsEnabled === 1) {
              this.tempArr[optionsIndex] = {
                value: element.camp_id,
                label: element.camp_name,
              };
              optionsIndex++;
            }
          }
          this.optionsCampaigns = this.tempArr;
          this.tempArr = [];
          this.resp = {};
        },
        err => { this.loadingfail = true }
      );
  }

  selectedCampaign() {
    this.campaignForm.get('camp_id').valueChanges
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe((value) => {
        this.selectedCampaignId = { camp_id: value };
        this.loadAllCampaignStats();
        counter
          .pipe(takeUntil(this.unsubCounter))
          .subscribe(n => {
            if (n < this.status.length) {
              this.statusText = this.status[n];
            } else {
              //n = 5
              this.statusText = this.status[6];
            }
          });
        this.selected = true;
      });
  }

  public chartClicked(e: any): void { }
  public chartHovered(e: any): void { }

  loadUniqueWeeklyCount(): Promise<any> {
    let dataPromise: Promise<any> = new Promise((resolve, reject) => {
      let campID = this.campaignForm.get('camp_id').value;
      let uniqpayload = {
        camp_id: campID,
        start_date: this.startdate,
        end_date: this.enddate
      }

      this.reportsCampaigns.getUniqueCountBetween(uniqpayload)
        .pipe(takeUntil(this.ngUnsubscribe))
        .subscribe(data => {
          this.uniqueResp = data;
          this.myWeeklyUniqueCounts = this.uniqueResp.uniqueCounts;

          this.weeklyDatasets = [{
            data: this.resp.weeklyCountArr,
            label: 'Total Messages',
            categoryPercentage: 0.95,
            barPercentage: 1.0
          },
          {
            //data: this.resp.weeklyUniqueCountArr,
            data: this.myWeeklyUniqueCounts,
            label: 'Unique Messages',
            categoryPercentage: 0.95,
            barPercentage: 1.0
          }
          ];
          resolve(true);
        });
    });
    return dataPromise;
  }

  loadAllCampaignStats() {
    this.payload = this.selectedCampaignId
    this.reportsCampaigns.getSelectedCampaignStats(this.payload)
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(
        data => {
          this.resp = data;
          this.userName = this.resp.userName;
          this.startdate = this.pipe.transform(this.resp.campaign.camp_startDte, 'yyyy-MM-dd');
          this.enddate = this.pipe.transform(this.resp.campaign.camp_endDte, 'yyyy-MM-dd');
          this.nowdate = this.pipe.transform(this.now, 'yyyy-MM-dd');
          this.dateStamp = this.pipe.transform(this.now, 'yyyy-MM-dd HH:mm:ss');
          this.campaignStatus = this.resp.campaign.camp_status;
          this.campaignType = this.resp.campaign.camp_type;
          if (this.nowdate > this.enddate) { this.nowdate = this.enddate }
          this.campaignDetail = {
            rperiod: this.startdate + ' to ' + this.nowdate,
            name: this.resp.campaign.camp_name,
            cperiod: this.startdate + ' to ' + this.enddate,
            scode: this.resp.shortcode.sc_number,
            svalue: this.resp.shortcode.sc_desc.substring(2)
          }
          this.fileNameToSave = this.campaignDetail.name + '_Report_.pdf';
          this.networkArr = this.resp.networkArr.reverse();
          this.totalCount = this.networkArr[this.networkArr.length - 1].count;
          this.totalUnique = this.resp.totalUniqueEntries;

          this.networkDatasets = [{
            data: [this.networkArr[this.networkArr.length - 1].vodacom,
            this.networkArr[this.networkArr.length - 1].cellc,
            this.networkArr[this.networkArr.length - 1].mtn,
            this.networkArr[this.networkArr.length - 1].aita,
            this.networkArr[this.networkArr.length - 1].other],
            label: 'Network Message Totals'
          }]

          this.hourlyDatasets = [{ data: this.resp.hourlyArr, label: 'Total Messages' }];
          this.timeslotDatasets = [{ data: this.resp.timeslotArr, label: 'Total Messages' }];
          this.dayOfWeekDatasets = [{ data: this.resp.dayArr, label: 'Total Messages' }];
          // this.resp.weeklyCountArr.splice(0,1);

          this.weeklyDatasets = [{
            data: this.resp.weeklyCountArr,
            label: 'Total Messages',
            categoryPercentage: 0.95,
            barPercentage: 1.0
          },
          {
            data: this.resp.weeklyUniqueCountArr,
            // data: this.myWeeklyUniqueCounts,
            label: 'Unique Messages',
            categoryPercentage: 0.95,
            barPercentage: 1.0
          }
          ];

          for (let index = 0; index < this.resp.weeklyDatesArr.length; index++) {
            const element = this.resp.weeklyDatesArr[index];
            this.weeklyLabels[index] = element
          }
          this.monthlyDatasets = [{ data: this.resp.monthlyArr, label: 'Total Messages' }];
          for (let index1 = 0; index1 < this.resp.monthlyArrMonths.length; index1++) {
            const element = this.resp.monthlyArrMonths[index1];
            this.monthlyLabels[index1] = element
          }

          //const dailyAve = this.exponentialMovingAverage(this.resp.dailyArrCount,5);
          this.dailyDatasets = [{
            data: this.resp.dailyArrCount, label: 'Total Messages', fill: false,
            datalabels: { display: true, anchor: 'end', align: 'end', offset: 10, rotation: -90, font: { family: 'ZonaBold', size: 13, weight: 'bold' } }
          },
          {
            data: this.resp.dailyArrUniqueCount, label: 'Unique Messages', fill: false,
            datalabels: { display: false }
          }
          ];
          for (let index2 = 0; index2 < this.resp.dailyArrDays.length; index2++) {
            const element = this.resp.dailyArrDays[index2];
            this.dailyLabels[index2] = element
          }
          this.dailyAve = this.resp.dailyAve;
          this.weeklyAve = this.resp.weeklyAve;

          for (let index3 = 0; index3 < this.resp.finalKeyArr.length; index3++) {
            const element = this.resp.finalKeyArr[index3].smsi_keyword;
            const element2 = this.resp.finalKeyArr[index3].count;
            this.keywordLabels[index3] = element;
            this.keyArrCount[index3] = element2;
          }
          for (let index3 = 0; index3 < this.resp.opt1FinalKeyArr.length; index3++) {
            const element = this.resp.opt1FinalKeyArr[index3].smsi_keyword;
            const element2 = this.resp.opt1FinalKeyArr[index3].count;
            this.optKeywordLabels[index3] = element;
            this.optKeyArrCount[index3] = element2;
          }
          this.keywordDatasets = [{ data: this.keyArrCount, label: 'Total Messages' }];
          //this.optKeywordDatasets = [ { data: this.optKeyArrCount, label: 'Total Messages' } ];
          this.optKeywordDatasets = this.resp.opt1FinalKeyArr.slice();

          const tableChunkSize = 20;
          const pageChunkSize = 3;
          // const tableChunkSize = 3;
          // const pageChunkSize = 3;
          const tableChunk = [];


          for (let i = 0; i < this.optKeywordDatasets.length; i += tableChunkSize) {
            const chunk = this.optKeywordDatasets.slice(i, i + tableChunkSize);
            tableChunk.push(chunk);
          }

          for (let i = 0; i < tableChunk.length; i += pageChunkSize) {
            const chunk = tableChunk.slice(i, i + pageChunkSize);
            this.pageChunk.push(chunk);
          }

          if (this.thebrowser === 'firefox') {
            setTimeout(() => {
              this.setHourGradient();
              this.setMonthlyColors(this.monthlyLabels.length);
            }, this.msWait);
          } else {
            //this.setHourGradient();
            this.setMonthlyColors(this.monthlyLabels.length);
          }

          /*
          if(this.thebrowser === 'firefox'){
            setTimeout (() => {
              console.log('monthly timeout')
              this.setMonthlyColors(this.monthlyLabels.length);
            }, this.msWait);
          }else{
            this.setMonthlyColors(this.monthlyLabels.length);
          }
          */

          if (typeof this.keywordLabels[0] != 'undefined') {
            this.showKeyword = true;
            if (this.thebrowser === 'firefox') {
              setTimeout(() => {
                this.setKeywordColors(this.keywordLabels.length);
              }, this.msWait);
            } else {
              this.setKeywordColors(this.keywordLabels.length);
            }
          }
          /*
          if(typeof this.optKeywordLabels[0] != 'undefined'){
            this.showOptKeyword = true;
            if(this.thebrowser === 'firefox'){
              setTimeout (() => {
                this.setOptKeywordColors(this.optKeywordLabels.length);
              }, this.msWait);
            }else{
              this.setOptKeywordColors(this.optKeywordLabels.length);
            }
          }
          */
          if (typeof this.optKeywordLabels[0] != 'undefined') {
            this.showOptKeyword = true;
          }


          for (let index3 = 0; index3 < this.resp.dailyTop5ArrDays.length; index3++) {
            const element = this.resp.dailyTop5ArrDays[index3].day;
            const element2 = this.resp.dailyTop5ArrDays[index3].count;
            this.top5DaysLabels[index3] = element;
            this.top5DaysArrCount[index3] = element2;
          }
          this.top5DaysDatasets = [{ data: this.top5DaysArrCount, label: 'Top 5 Days' }];

          this.loaded = true;
          this.unsubCounter.next();
          this.ngUnsubscribe.next();

        },
        err => { this.ngOnInit(); },
        // () => {
        //   this.loadUniqueWeeklyCount().then((response) => {
        //     this.loaded = true;
        //   });
        // }
      );

  }

  resetPage() {
    this.router.navigate(['/shortcode/campaign/reports/report']);
    this.ngOnInit();
  }

  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
      heading: '',
      content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: '' }
    }
  }

  setHourGradient() {
    const ctx = this.hourChart.nativeElement.getContext('2d');

    const gradient = ctx.createLinearGradient(0, 0, 1240, 0);
    gradient.addColorStop(0, 'rgba(113, 76, 140, .2)');
    gradient.addColorStop(1, 'rgba(113, 76, 140, 1)');

    this.hourlyColors = [
      {
        backgroundColor: gradient,
        borderColor: gradient,
        borderWidth: 2,
      }
    ];
  }

  setNetworkGradient() {
    const canvas = this.networkChart.nativeElement;
    const canvasHeight = canvas.height + 150;

    const gradient1 = this.networkChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 100, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient1.addColorStop(0, 'rgba(40,153,213, 1)');
    gradient1.addColorStop(1, 'rgba(40,153,213, .4)');

    const gradient2 = this.networkChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient2.addColorStop(0, 'rgba(162,197,29, 1)');
    gradient2.addColorStop(1, 'rgba(162,197,29, .4)');

    const gradient3 = this.networkChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient3.addColorStop(0, 'rgba(113,76,140, 1)');
    gradient3.addColorStop(1, 'rgba(113,76,140, .4)');

    const gradient4 = this.networkChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient4.addColorStop(0, 'rgba(229,6,127, 1)');
    gradient4.addColorStop(1, 'rgba(229,6,127, .4)');

    const gradient5 = this.networkChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient5.addColorStop(0, 'rgba(255, 166, 0, 1)');
    gradient5.addColorStop(1, 'rgba(255, 166, 0, .4)');

    this.networkColors = [
      {
        backgroundColor: [
          gradient1, gradient2, gradient3, gradient4, gradient5
        ],
        borderColor: [
          'white'
        ],
        borderWidth: 2
      }
    ]
  }

  setTimeSlotGradient() {
    const canvas = this.timeslotChart.nativeElement;
    const canvasHeight = canvas.height + 150;

    const gradient1 = this.timeslotChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient1.addColorStop(0, 'rgba(40,153,213, 1)');
    gradient1.addColorStop(1, 'rgba(40,153,213, 0.4)');

    const gradient2 = this.timeslotChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient2.addColorStop(0, 'rgba(162,197,29, 1)');
    gradient2.addColorStop(1, 'rgba(162,197,29, 0.4)');

    const gradient3 = this.timeslotChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient3.addColorStop(0, 'rgba(113,76,140, 1)');
    gradient3.addColorStop(1, 'rgba(113,76,140, 0.4)');

    const gradient4 = this.timeslotChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient4.addColorStop(0, 'rgba(229,6,127, 1)');
    gradient4.addColorStop(1, 'rgba(229,6,127, 0.4)');

    const gradient5 = this.timeslotChart.nativeElement.getContext('2d').
      createRadialGradient(canvas.width / 2, canvasHeight / 2, canvasHeight / 8, canvas.width / 2, canvasHeight / 2, canvasHeight / 2);
    gradient5.addColorStop(0, 'rgba(255, 166, 0, 1)');
    gradient5.addColorStop(1, 'rgba(255, 166, 0, 0.4)');

    /*
    this.doughnutColors = [
      {
        backgroundColor: function(context){
          const colors = ['rgba(3, 169, 244, 0.7)','rgba(244, 67, 54, 0.7)','rgba(233, 30, 99, 0.7)','rgba(156, 39, 176, 0.7)'];
          let c = colors[context.dataIndex];
          if (!c) {
            return;
          }
          if (context.active) {
            c = helpers.getHoverColor(c);
          }
          const mid = helpers.color(c).desaturate(0.2).darken(0.2).rgbString();
          const start = helpers.color(c).lighten(0.2).rotate(270).rgbString();
          const end = helpers.color(c).lighten(0.1).rgbString();
          return createRadialGradient3(context, start, mid, end);
        },
        borderColor: [
          'white'
        ],
        borderWidth: 2
      }
    ]
    */

    this.doughnutColors = [
      {
        backgroundColor: [
          gradient1, gradient2, gradient3, gradient4, gradient5
        ],
        borderColor: [
          'white'
        ],
        borderWidth: 2
      }
    ]

  }

  /*
  createRadialGradient3(context, c1, c2, c3) {
    const chartArea = context.chart.chartArea;
    if (!chartArea) {
      // This case happens on initial chart load
      return null;
    }
  
    const chartWidth = chartArea.right - chartArea.left;
    const chartHeight = chartArea.bottom - chartArea.top;
    if (this.width !== chartWidth || this.height !== chartHeight) {
      cache.clear();
    }
    var gradient = cache.get(c1 + c2 + c3);
    if (!gradient) {
      // Create the gradient because this is either the first render
      // or the size of the chart has changed
      this.width = chartWidth;
      this.height = chartHeight;
      const centerX = (chartArea.left + chartArea.right) / 2;
      const centerY = (chartArea.top + chartArea.bottom) / 2;
      const r = Math.min(
        (chartArea.right - chartArea.left) / 2,
        (chartArea.bottom - chartArea.top) / 2
      );
      var ctx = context.chart.ctx;
      gradient = ctx.createRadialGradient(centerX, centerY, 0, centerX, centerY, r);
      gradient.addColorStop(0, c1);
      gradient.addColorStop(0.5, c2);
      gradient.addColorStop(1, c3);
      cache.set(c1 + c2 + c3, gradient);
    }
  
    return gradient;
  }
  */
  setDaysOfWeekGradient() {
    const gradient = this.daysOfWeekChart.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 600);
    gradient.addColorStop(0, 'rgba(162,197,29, 1)');
    gradient.addColorStop(1, 'rgba(162,197,29, .3)');
    this.dayOfWeekColors = [
      {
        backgroundColor: [
          gradient, gradient, gradient, gradient, gradient, gradient, gradient
        ],
        borderColor: [
          gradient, gradient, gradient, gradient, gradient, gradient, gradient
        ],
        borderWidth: 1
      }
    ]
  }

  setMonthlyColors(dataItems: number) {
    let mc = this.chartList.find(chart => chart.ctx.canvas.id === 'monthlyChart');
    let mColors: Array<string> = [];
    for (let index = 0; index < dataItems; index++) {
      for (let c = 0; c < this.stoufColors.length; c++) {
        if (index === dataItems) {
          break;
        } else {
          mColors[index] = this.stoufColors[c];
          index++;
        }
      }
      index--;
    }
    this.monthlyColors = [
      {
        backgroundColor: mColors,
        borderColor: mColors,
        borderWidth: 1
      }
    ]
    //this.monthlyChart.forEach(tabInstance => console.log(tabInstance.ctx.canvas.id));
    mc.colors = this.monthlyColors;
    mc.ngOnInit();
  }

  setKeywordColors(dataItems: number) {
    let mc = this.chartList.find(chart => chart.ctx.canvas.id === 'keywordChart');
    let mColors: Array<string> = [];
    for (let index = 0; index < dataItems; index++) {
      for (let c = 0; c < this.stoufColors.length; c++) {
        if (index === dataItems) {
          break;
        } else {
          mColors[index] = this.stoufColors[c];
          index++;
        }
      }
      index--;
    }
    this.keywordColors = [
      {
        backgroundColor: mColors,
        borderColor: mColors,
        borderWidth: 1
      }
    ]
    mc.colors = this.keywordColors;
    mc.ngOnInit();
  }

  setOptKeywordColors(dataItems: number) {
    let optKw = this.chartList.find(chart => chart.ctx.canvas.id === 'optKeywordChart');
    let mColors: Array<string> = []; for (let index = 0; index < dataItems; index++) {
      for (let c = 0; c < this.stoufColors.length; c++) {
        if (index === dataItems) {
          break;
        } else {
          mColors[index] = this.stoufColors[c];
          index++;
        }
      }
      index--;
    }
    this.optKeywordColors = [
      {
        backgroundColor: mColors,
        borderColor: mColors,
        borderWidth: 1
      }
    ]
    //this.monthlyChart.forEach(tabInstance => console.log(tabInstance.ctx.canvas.id));
    optKw.colors = this.optKeywordColors;
    optKw.ngOnInit();
  }

  simpleMovingAverage(prices, window = 5, n): Array<any> {
    if (!prices || prices.length < window) {
      return [];
    }

    let index = window - 1;
    const length = prices.length + 1;

    const simpleMovingAverages = [];

    let numberOfSMAsCalculated = 0;

    while (++index < length && numberOfSMAsCalculated++ < n) {
      const windowSlice = prices.slice(index - window, index);
      const sum = windowSlice.reduce((prev, curr) => prev + curr, 0);
      simpleMovingAverages.push(sum / window);
    }

    return simpleMovingAverages;
  }

  exponentialMovingAverage(prices, window = 5): Array<any> {
    if (!prices || prices.length < window) {
      return [];
    }

    let index = window - 1;
    let previousEmaIndex = 0;
    const length = prices.length;
    const smoothingFactor = 2 / (window + 1);

    const exponentialMovingAverages = [];

    const [sma] = this.simpleMovingAverage(prices, window, 1);
    exponentialMovingAverages.push(sma);

    while (++index < length) {
      const value = prices[index];
      const previousEma = exponentialMovingAverages[previousEmaIndex++];
      const currentEma = (value - previousEma) * smoothingFactor + previousEma;
      exponentialMovingAverages.push(currentEma);
    }

    return exponentialMovingAverages;
  }


  setWeeklyGradient() {
    const gradient1 = this.weeklyChart.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 777);
    gradient1.addColorStop(0, 'rgba(229, 6, 127, 1)');
    gradient1.addColorStop(1, 'rgba(229, 6, 127, .3)');

    const gradient2 = this.weeklyChart.nativeElement.getContext('2d').createLinearGradient(0, 0, 0, 777);
    gradient2.addColorStop(0, 'rgba(162,197,29, 1)');
    gradient2.addColorStop(1, 'rgba(162,197,29, .3)');

    this.weeklyColors = [
      { backgroundColor: 'rgba(229, 6, 127, 0.7)' },
      { backgroundColor: 'rgba(162,197,29, 0.3)' }
    ]

  }

  detectBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase()
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  ngAfterViewInit() {
    if (this.thebrowser !== 'firefox') {
      this.setHourGradient();
    }
    //this.setTimeSlotGradient();
    //this.setNetworkGradient();
  }

  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

}
