import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MDBModalRef, MDBModalService } from 'ng-uikit-pro-standard';

import { LoginService } from '../../login.service';
import { SuccessModalComponent } from '../../modals/other/success-modal/success-modal.component';
import { ErrorComponent } from '../../modals/other/error/error.component';
import { LoaderService } from '../../services/loader.service';
import { ActivatedRoute, Router } from '@angular/router';
import { RandomWinnerService } from '../../services/random-winner.service';

@Component({
  selector: 'app-random-winner-email-modal',
  templateUrl: './random-winner-email-modal.component.html',
  styleUrls: ['./random-winner-email-modal.component.scss']
})
export class RandomWinnerEmailModalComponent implements OnInit, OnDestroy {
  private ngUnsubscribe: Subject<any> = new Subject();
  action: Subject <any> = new Subject();
  
  registerForm: FormGroup;
  public submitted: boolean;
  content: {winnerArr: Array<any>};
  private payload: any;
  private resp: any;
  public loading: boolean;
  public loadingtext: string;
	public codeTrim: string;
	private emailArr: Array<any>;

	public emailTemplate: boolean;

  constructor(
    public modalRef2: MDBModalRef,
    public modalRef: MDBModalRef,
    private fb: FormBuilder,
    private loginService: LoginService,
    private modalService: MDBModalService,
    private actRoute: ActivatedRoute,
    private router: Router,
		private loaderService: LoaderService,
		private randomWinnerService: RandomWinnerService,
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
		this.emailTemplate = false;
		this.emailArr = [];
    this.codeTrim = '';
    this.submitted = false;
    this.loadingtext = '';
    this.payload = {};
    this.registerForm = this.fb.group({
      user_activate_code: ['', [Validators.required, Validators.pattern(/^(([a-zA-Z0-9_\-\.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([a-zA-Z0-9\-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)(\s*;\s*|\s*$))*$/)]],
      });
  }

  get p() {return this.registerForm.controls; }
  get user_activate_code() {return this.registerForm.get('user_activate_code'); }

  onSubmit() {
    this.submitted = true;

     // stop if invalid
    if (this.registerForm.invalid) {
      this.openModalError();
      return;
    }

    this.activateUser();
  }

  activateUser() {
    this.codeTrim = this.user_activate_code.value;
		this.loadingtext = 'Sending Email...';
		this.codeTrim = this.codeTrim.replace(/\s/g, '')
		if(this.codeTrim.endsWith(';')) {
			let tmp = this.codeTrim.lastIndexOf(';');
			this.codeTrim = this.codeTrim.substring(0,tmp)
		}
		this.emailArr = this.codeTrim.split(';');
		console.log(this.emailArr)
		this.payload = {
			email: this.emailArr,
			winners: this.content.winnerArr
		}
		this.randomWinnerService.emailRandomWinners(this.payload)
    .pipe(takeUntil(this.ngUnsubscribe))
    .subscribe(
      data => {
				this.resp = data;
				// console.log(data)    
				this.modalRef2.hide();
				this.action.next('true'); 
				this.openModalSuccess();
      },
      err => {
				// console.log(err)
      }
      );
  }


  modalOptionsSuccess = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-success',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', btnText: ''}
    }
  }


  openModalSuccess() {
    this.modalOptionsSuccess.data = {
      heading: 'Email Sent Successfully!',
      content: {
        heading: 'Everything looks good! Random Winner list been successfully sent.',
        btnText: 'Awesome'
      }
    }
    this.modalRef = this.modalService.show(SuccessModalComponent, this.modalOptionsSuccess)
  }


  modalOptionsError = {
    backdrop: true,
    keyboard: true,
    focus: true,
    show: false,
    ignoreBackdropClick: false,
    class: 'modal-dialog modal-notify modal-danger',
    containerClass: 'modal fade',
    animated: true,
    data: {
        heading: '',
        content: { heading: '', suggest: '', fix1: '', fix2: '', fix3: '', btnText: ''}
    }
  }


  openModalError() {
    this.modalOptionsError.data = {
      heading: 'Oops...',
      content: {
        heading: 'There seems to be a problem with your email address.',
        suggest: 'Suggested Actions:',
        fix1: 'Ensure that you have provided at least one email address.',
        fix2: 'Ensure that your email address(es) is valid.',
        fix3: 'Ensure that email addresses are separated with semi-colons (;) .',
        btnText: 'lets see'
      }
    }
    this.modalRef = this.modalService.show(ErrorComponent, this.modalOptionsError)
  }


  ngOnDestroy(): any {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }
}

