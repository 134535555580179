import { Component, OnInit } from '@angular/core';
import { LoaderService } from '../../../../services/loader.service';
@Component({
  selector: 'app-view-book',
  templateUrl: './view-book.component.html',
  styleUrls: ['./view-book.component.scss']
})
export class ViewBookComponent implements OnInit {

  public loading: boolean;
  public edit: boolean;
  public currentState: string;
  public phonebookId: {pbid: number, col1: string, col2: string, col3: string, col4: string};
  
  constructor(
    private loaderService: LoaderService
    ) {this.loaderService.isLoading.subscribe((v) => {this.loading = v;});}


  ngOnInit() {
    this.loading = true;
    this.edit = false;
    this.currentState = '';
    this.phonebookId = {pbid: 0, col1: '', col2: '', col3: '', col4: ''}
  }

  onEditedPhonebook(editData: {edit: boolean, phonebookName: string, phonebookId: number, col1: string, col2: string, col3: string, col4: string}) {
    this.edit = editData.edit;
    this.currentState = editData.phonebookName;
    this.phonebookId = {pbid: editData.phonebookId, col1: editData.col1, col2: editData.col2, col3: editData.col3, col4: editData.col4};
  }
   
  }

