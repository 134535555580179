import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from "../../environments/environment";

const apiUrl = environment.liveReadUrl;
const liveReadUrl2 = environment.liveReadUrl2;

@Injectable({
  providedIn: 'root'
})
export class LiveReaderService {

  constructor(private http: HttpClient) { }
  token = localStorage.getItem('Authorization');

  httpOptions = {
    headers: {}
  };
   
  getLive(payload) {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.http.post(apiUrl, payload, this.httpOptions);
    }return;
    
  }

  getLiveReply() {
    if (this.token !== null) {
      this.httpOptions = {
        headers: {
          'Authorization': this.token.replace(/^"(.*)"$/, '$1'),
          'Content-Type': 'application/json'
        }
      };
      return this.http.get(liveReadUrl2, this.httpOptions);
    }return;
    
  }
  }